import React from 'react';

import { Filter, More, NoResult as NoSearchResult } from '@travel/icons/ui';
import { Translate } from '@travel/translation';

import TotalCount from '../TotalCount';

import cx from '../../utils/classnames';

import styles from './noResult.module.scss';

type Props = {
  /** Custom style for wrapper */
  className: string;
  /** Flag to display correct No Result message for Page / Dialog that doesn't have create page */
  isMiniMessage?: boolean;
  /** control button (current usage is from booking list only) */
  controlButton?: React.ReactNode;
};

function NoResult(props: Props) {
  const { className, isMiniMessage, controlButton, ...rest } = props;

  return (
    <div className={cx(className, styles.wrapper)} {...rest}>
      <span className={styles.zeroCount}>
        <Translate
          id="Common.List.Search.Search_Count"
          data={{ num_of_search_results: <TotalCount total={0} /> }}
        />
        {controlButton && controlButton}
      </span>
      {isMiniMessage ? (
        <Translate id="Common.Search.No_Results.Mini_Message" />
      ) : (
        <Translate
          id="Common.List.Search.No_Results"
          data={{
            icon1: <More className={styles.noResultIcons} />,
            icon2: <Filter className={styles.noResultIcons} />,
          }}
        />
      )}
      <NoSearchResult className={styles.mainIcon} />
    </div>
  );
}

NoResult.defaultProps = {
  isMiniMessage: false,
  className: '',
};

export default NoResult;
