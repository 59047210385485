import isNotEmptyArray from '@travel/ui/utils/arrays';

export default () => {
  if (typeof window !== 'undefined' || process.env.NODE_ENV === 'development') {
    return console;
  } else {
    const { createLogger, transports, format } = require('winston');
    const { join } = require('path');
    const { inspect } = require('util');
    const { timestamp, printf, combine } = format;

    let filename = 'app-error.log';
    let infoFileName = 'app-info.log';
    if (process.env.NODE_ENV === 'production' && !process.env.LOCAL_PROD) {
      filename = join('/home/docker/.pm2/logs', '/app-error.log');
      infoFileName = join('/home/docker/.pm2/logs', '/app-info.log');
    }

    const transportError = new transports.File({
      level: 'error',
      filename,
    });
    const infoTransport = new transports.File({
      level: 'info',
      filename: infoFileName,
    });

    const formatLog = printf((info: any) => {
      const { message, timestamp, stack } = info;
      const args = info[Symbol.for('splat')];
      const logs = [timestamp];
      if (typeof message === 'object') {
        logs.push(inspect(message));
      } else {
        logs.push(message);
      }
      if (stack) {
        logs.push(stack);
      }
      if (isNotEmptyArray(args)) {
        args.forEach(arg => {
          if (typeof arg === 'object') {
            logs.push(inspect(arg));
          } else {
            logs.push(arg);
          }
        });
      }
      return logs.join(' ');
    });

    return createLogger({
      format: combine(timestamp(), formatLog),
      transports: [infoTransport, transportError, new transports.Console()],
    });
  }
};
