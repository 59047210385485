/** TotalCount is a reusable component for displaying total results value with uniform styling .*/
import React from 'react';

import cx from '../../utils/classnames';

import styles from './totalCount.module.scss';

type Props = {
  total: number | string;
  className?: string;
  testID?: string;
};

function TotalCount({ total, className, testID }: Props) {
  const totalValue = total || 0;
  return (
    <span className={cx(styles.totalCount, className)} data-testid={testID}>
      {totalValue}
    </span>
  );
}

export default TotalCount;
