import { ReactNode } from 'react';

import env from '@travel/env';
import { isEmptyObject, isNotEmptyArray, stringify, toQuery } from '@travel/utils';

type Option = {
  value: string;
  text: ReactNode;
};

type SortData = {
  options: Option[];
  selectedValue: string | number;
};

export const setSort = <T extends { [key: string]: string | string[] } | undefined>(
  query: T,
  sortData: SortData,
) => {
  if (isEmptyObject(query) || !isNotEmptyArray(sortData.options)) return sortData;

  const sortValue = sortData.options.find((option: Option) => {
    const optionData = option.value.split('=');
    return (
      query &&
      Object.keys(query).includes(optionData[0]) &&
      Object.values(query).includes(optionData[1])
    );
  });

  const selectedValue = sortValue ? sortValue.value : sortData.options[0].value;

  return {
    options: sortData.options,
    selectedValue,
  };
};

/**
 * creates the path to the image file in the public folder
 * root: public/static/media/< filePath >
 * @param filePath Filename or path where the root is   public/static/media/
 */
export const makePublicImagePath = (filePath: string) => {
  const assetPath = env('UNIVERSAL_PUBLIC_IMAGE_PATH') || '';

  return filePath.includes(assetPath) ? filePath : `${assetPath}${filePath}`;
};

const createNewPath = (
  pathname: string,
  params: {
    keyword: string;
    filter?: {
      label: string;
      id: string;
      options: { label: string; value: string; isCheck: boolean }[];
    }[];
  },
  sort: string,
) => {
  const query = stringify(toQuery(params)).concat(sort ? `&sort.${sort}` : '');
  return {
    pathname,
    search: query,
  };
};

export const getProviderRelatedIdInUrl = (baseUrl: string): string => {
  const providerId = baseUrl.match(/^\/provider\/(.*)$/)?.[1]?.split('/')[0] || '';
  const providerGroupId = baseUrl.match(/^\/providerGroup\/(.*)$/)?.[1]?.split('/')[0] || '';
  return providerId || providerGroupId;
};

export default createNewPath;
