import loadable from '@loadable/component';
import React from 'react';

import App from 'core/App';
import accessControlResolver from 'pages/AccessControl/resolver';
import accessControlDetailResolver from 'pages/AccessControlCreate/resolver';
import aliasCreateResolver from 'pages/AliasCreate/resolver';
import aliasResolver from 'pages/Aliases/resolver';
import announcementDetailResolver from 'pages/AnnouncementCreate/resolver';
import announcementListResolver from 'pages/AnnouncementList/resolver';
import extranetApplicationDetailResolver from 'pages/ApplicationDetail/resolver';
import applicationTrackerResolver from 'pages/ApplicationTrackerList/resolver';
import bookingDetailResolver from 'pages/BookingDetail/resolver';
import bookingListResolver from 'pages/BookingList/resolver';
import campaignListResolver from 'pages/Campaign/resolver';
import campaignResolver from 'pages/CampaignCreate/resolver';
import channelManagerManagementEditResolver from 'pages/ChannelManagerManagementEdit/resolver';
import channelManagerProviderListResolver from 'pages/ChannelManagerProviderList/resolver';
import channelMangerManagementListResolver from 'pages/ChannelMangerManagementList/resolver';
import createProviderResolver from 'pages/CreateProvider/resolver';
import channelManagerDistributorEditResolver from 'pages/DistributorManagementEdit/resolver';
import channelManagerDistributorListResolver from 'pages/DistributorManagementList/resolver';
import faxManagementResolver from 'pages/FaxManagement/resolver';
import featureDetailResolver from 'pages/FeatureCreate/resolver';
import featureGroupDetailResolver from 'pages/FeatureGroupCreate/resolver';
import featureGroupListResolver from 'pages/FeatureGroupList/resolver';
import featureListResolver from 'pages/FeatureList/resolver';
import filterComponentFormResolver from 'pages/FilterComponentForm/resolver';
import filterComponentResolver from 'pages/FilterComponents/resolver';
import filterGroupCreateResolver from 'pages/FilterGroupCreate/resolver';
import filterGroupResolver from 'pages/FilterGroups/resolver';
import goToPromotionDetailResolver from 'pages/GoToPromotionDetail/resolver';
import goToPromotionListResolver from 'pages/GoToPromotionList/resolver';
import labelDetailResolver from 'pages/LabelManagementLabeDetail/resolver';
import labelListResolver from 'pages/LabelManagementLabeList/resolver';
import pageEditResolver from 'pages/LabelManagementPageDetail/resolver';
import { Products } from 'pages/LabelManagementPageList';
import pagesListResolver from 'pages/LabelManagementPageList/resolver';
import luxuryInvitationResolver from 'pages/LuxuryInvitation/resolver';
import manualTranslationResolver from 'pages/ManualTranslation/resolver';
import mediaDetailResolver from 'pages/MediaDetail/resolver';
import mediaListResolver from 'pages/MediaList/resolver';
import multiManualTranslationResolver from 'pages/MultiManualTranslation/resolver';
import multiManualTranslationUploadResolver from 'pages/MultiManualTranslationUpload/resolver';
import notificationDetailResolver from 'pages/NotificationDetail/resolver';
import notificationInboxResolver from 'pages/NotificationInbox/resolver';
import objectScoreResolver from 'pages/ObjectScore/resolver';
import PlaceDetailResolver from 'pages/PlaceCreate/resolver';
import placeListResolver from 'pages/PlaceList/resolver';
import providerGroupDetailResolver from 'pages/ProviderGroupDetail/resolver';
import providerGroupListResolver from 'pages/ProviderGroupList/resolver';
import providerResolver from 'pages/ProviderList/resolver';
import providerRestrictionDetailResolver from 'pages/ProviderRestrictionDetail/resolver';
import providerRestrictionListResolver from 'pages/ProviderRestrictionList/resolver';
import reviewExamDetailResolver from 'pages/ReviewExamDetail/resolver';
import reviewExamHistoryResolver from 'pages/ReviewExamHistory/resolver';
import reviewExamListResolver from 'pages/ReviewExamList/resolver';
import salesPromotionResolver from 'pages/SalesPromotion/resolver';
import SalesPromotionDetailResolver from 'pages/SalesPromotionDetail/resolver';
import SkeletonPage from 'pages/SkeletonPage';
import userSegmentResolver from 'pages/UserSegment/resolver';
import visualContentGroupDetailResolver from 'pages/VisualContentGroupCreate/resolver';
import visualContentGroupListResolver from 'pages/VisualContentGroupList/resolver';

import { SITE_MENU_MAP } from 'constants/site-menu';
import { universalRouterProps } from './types';

export default (props: universalRouterProps) => {
  const { req } = props;
  const isServer = !!req;

  return [
    {
      path: '/internal',
      async action(context: any) {
        const children = await context.next();
        return <App {...context}>{children}</App>;
      },
      children: [
        {
          path: '',
          async action(context: any) {
            const Home = loadable(() => import(/* webpackChunkName: "pages-Home" */ 'pages/Home'));
            return <Home {...context} />;
          },
        },
        {
          path: '/provider',
          authKey: 'ProvMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const ProviderList = loadable(
              () => import(/* webpackChunkName: "pages-ProviderList" */ 'pages/ProviderList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await providerResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              providerResolver(props, context.query || {});
            }

            return <ProviderList {...context} />;
          },
        },
        {
          path: '/provider/create',
          authKey: 'ProvMgmt',
          isCreate: true,
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const CreateProvider = loadable(
              () => import(/* webpackChunkName: "pages-CreateProvider" */ 'pages/CreateProvider'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await createProviderResolver(props, context);
            } else if (!context.skipResolver) {
              createProviderResolver(props, context);
            }

            return <CreateProvider {...context} />;
          },
        },
        {
          path: '/provider/:providerId',
          authKey: 'ProvMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const CreateProvider = loadable(
              () => import(/* webpackChunkName: "pages-CreateProvider" */ 'pages/CreateProvider'),
              {
                fallback: <SkeletonPage pageType={'detail'} hasMedia />,
              },
            );
            if (isServer) {
              await createProviderResolver(props, context);
            } else if (!context.skipResolver) {
              createProviderResolver(props, context);
            }

            return <CreateProvider {...context} />;
          },
        },
        {
          path: '/providerGroup',
          authKey: 'ProvGropMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const ProviderGroupList = loadable(
              () =>
                import(/* webpackChunkName: "pages-ProviderGroupList" */ 'pages/ProviderGroupList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await providerGroupListResolver(props, context);
            } else if (!context.skipResolver) {
              providerGroupListResolver(props, context);
            }

            return <ProviderGroupList {...context} />;
          },
        },
        {
          path: '/providerGroup/create',
          authKey: 'ProvGropMgmt',
          isCreate: true,
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const ProviderGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderGroupDetail" */ 'pages/ProviderGroupDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await providerGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              providerGroupDetailResolver(props, context);
            }

            return <ProviderGroupDetail {...context} />;
          },
        },
        {
          path: '/providerGroup/:providerGroupId',
          authKey: 'ProvGropMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const ProviderGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderGroupDetail" */ 'pages/ProviderGroupDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await providerGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              providerGroupDetailResolver(props, context);
            }

            return <ProviderGroupDetail {...context} />;
          },
        },
        {
          path: '/providerRestrictions',
          authKey: SITE_MENU_MAP.PROVIDER_RESTRICTION_MANAGEMENT.authKey,
          async action(context: any) {
            const ProviderRestrictionList = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderRestrictionList" */ 'pages/ProviderRestrictionList'
                ),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await providerRestrictionListResolver(props, context);
            } else if (!context.skipResolver) {
              await providerRestrictionListResolver(props, context);
            }

            return <ProviderRestrictionList {...context} />;
          },
        },
        {
          path: '/providerRestrictions/:providerRestrictionId',
          authKey: SITE_MENU_MAP.PROVIDER_RESTRICTION_MANAGEMENT.authKey,
          async action(context: any) {
            const ProviderRestrictionDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-ProviderRestrictionDetail" */ 'pages/ProviderRestrictionDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await providerRestrictionDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await providerRestrictionDetailResolver(props, context);
            }

            return <ProviderRestrictionDetail {...context} />;
          },
        },
        {
          path: '/salespromotion/create',
          authKey: 'RakutenSP',
          isCreate: true,
          async action(context: any) {
            const SalesPromotion = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-SalesPromotionDetail" */ 'pages/SalesPromotionDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await SalesPromotionDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await SalesPromotionDetailResolver(props, context);
            }

            return <SalesPromotion {...context} />;
          },
        },
        {
          path: '/salespromotion/:promotionId',
          authKey: 'RakutenSP',
          async action(context: any) {
            const SalesPromotion = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-SalesPromotionDetail" */ 'pages/SalesPromotionDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await SalesPromotionDetailResolver(props, context);
            } else if (!context.skipResolver) {
              SalesPromotionDetailResolver(props, context);
            }

            return <SalesPromotion {...context} />;
          },
        },
        {
          path: '/salespromotion',
          authKey: 'RakutenSP',
          async action(context: any) {
            const SalesPromotion = loadable(
              () => import(/* webpackChunkName: "pages-SalesPromotion" */ 'pages/SalesPromotion'),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );
            if (isServer) {
              await salesPromotionResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              salesPromotionResolver(props, context.query || {});
            }

            return <SalesPromotion {...context} />;
          },
        },
        {
          path: '/gotoCoupons/create',
          authKey: 'GoToCpnMgmt',
          async action(context: any) {
            const GoToPromotionDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-GoToPromotionDetail" */ 'pages/GoToPromotionDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await goToPromotionDetailResolver(props, context);
            } else if (!context.skipResolver) {
              goToPromotionDetailResolver(props, context);
            }

            return <GoToPromotionDetail {...context} />;
          },
        },
        {
          path: '/gotoCoupons/:promotionId',
          authKey: 'GoToCpnMgmt',
          async action(context: any) {
            const GoToPromotionDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-GoToPromotionDetail" */ 'pages/GoToPromotionDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await goToPromotionDetailResolver(props, context);
            } else if (!context.skipResolver) {
              goToPromotionDetailResolver(props, context);
            }

            return <GoToPromotionDetail {...context} />;
          },
        },
        {
          path: '/gotoCoupons',
          authKey: 'GoToCpnMgmt',
          async action(context: any) {
            const GoToCouponList = loadable(
              () =>
                import(/* webpackChunkName: "pages-GoToPromotionList" */ 'pages/GoToPromotionList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );
            if (isServer) {
              await goToPromotionListResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              goToPromotionListResolver(props, context.query || {});
            }

            return <GoToCouponList {...context} />;
          },
        },
        {
          path: '/feature',
          authKey: 'FeatMgmt',
          async action(context: any) {
            const FeatureList = loadable(
              () => import(/* webpackChunkName: "pages-FeatureList" */ 'pages/FeatureList'),
              {
                fallback: <SkeletonPage pageType={'list'} />,
              },
            );

            if (isServer) {
              await featureListResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              featureListResolver(props, context.query || {});
            }

            return <FeatureList {...context} />;
          },
        },
        {
          path: '/feature/create',
          authKey: 'FeatMgmt',
          isCreate: true,
          async action(context: any) {
            const FeatureCreate = loadable(
              () => import(/* webpackChunkName: "pages-FeatureCreate" */ 'pages/FeatureCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await featureDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await featureDetailResolver(props, context);
            }
            return <FeatureCreate {...context} />;
          },
        },
        {
          path: '/feature/:featureId',
          authKey: 'FeatMgmt',
          async action(context: any) {
            const FeatureCreate = loadable(
              () => import(/* webpackChunkName: "pages-FeatureCreate" */ 'pages/FeatureCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await featureDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await featureDetailResolver(props, context);
            }
            return <FeatureCreate {...context} />;
          },
        },
        {
          path: '/featureGroup',
          authKey: 'FeatGrpMgmt',
          async action(context: any) {
            const FeatureGroupList = loadable(
              () =>
                import(/* webpackChunkName: "pages-FeatureGroupList" */ 'pages/FeatureGroupList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await featureGroupListResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              featureGroupListResolver(props, context.query || {});
            }

            return <FeatureGroupList {...context} />;
          },
        },
        {
          path: '/featureGroup/create',
          authKey: 'FeatGrpMgmt',
          isCreate: true,
          async action(context: any) {
            const FeatureGroupCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-FeatureGroupCreate" */ 'pages/FeatureGroupCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await featureGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await featureGroupDetailResolver(props, context);
            }
            return <FeatureGroupCreate {...context} />;
          },
        },
        {
          path: '/featureGroup/:featureGroupId',
          authKey: 'FeatGrpMgmt',
          async action(context: any) {
            const FeatureGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-FeatureGroupCreate" */ 'pages/FeatureGroupCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await featureGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await featureGroupDetailResolver(props, context);
            }
            return <FeatureGroupDetail {...context} />;
          },
        },
        {
          path: '/booking',
          authKey: 'BookMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const BookingList = loadable(() =>
              import(/* webpackChunkName: "pages-BookingList" */ 'pages/BookingList'),
            );

            if (!isServer) {
              bookingListResolver(props, context);
            }

            return <BookingList {...context} />;
          },
        },
        {
          path: '/booking/:bookingId',
          authKey: 'BookMgmt',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const BookingDetail = loadable(() =>
              import(/* webpackChunkName: "pages-BookingDetail" */ 'pages/BookingDetail'),
            );

            if (isServer) {
              await bookingDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await bookingDetailResolver(props, context);
            }

            return <BookingDetail {...context} />;
          },
        },
        {
          path: '/places',
          authKey: 'PlcSpotMgmt',
          async action(context: any) {
            const PlaceList = loadable(
              () => import(/* webpackChunkName: "pages-PlaceList" */ 'pages/PlaceList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await placeListResolver(props, context);
            } else if (!context.skipResolver) {
              placeListResolver(props, context);
            }
            return <PlaceList {...context} />;
          },
        },
        {
          path: '/places/create',
          authKey: 'PlcSpotMgmt',
          isCreate: true,
          async action(context: any) {
            const PlaceCreate = loadable(
              () => import(/* webpackChunkName: "pages-PlaceCreate" */ 'pages/PlaceCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await PlaceDetailResolver(props, context);
            } else if (!context.skipResolver) {
              PlaceDetailResolver(props, context);
            }
            return <PlaceCreate {...context} />;
          },
        },
        {
          path: '/places/create/:parentId',
          authKey: 'PlcSpotMgmt',
          isCreate: true,
          async action(context: any) {
            const PlaceCreate = loadable(
              () => import(/* webpackChunkName: "pages-PlaceCreate" */ 'pages/PlaceCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await PlaceDetailResolver(props, context);
            } else if (!context.skipResolver) {
              PlaceDetailResolver(props, context);
            }
            return <PlaceCreate {...context} />;
          },
        },
        {
          path: '/places/:placeId',
          authKey: 'PlcSpotMgmt',
          async action(context: any) {
            const PlaceCreate = loadable(
              () => import(/* webpackChunkName: "pages-PlaceCreate" */ 'pages/PlaceCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await PlaceDetailResolver(props, context);
            } else if (!context.skipResolver) {
              PlaceDetailResolver(props, context);
            }

            return <PlaceCreate {...context} />;
          },
        },
        {
          path: '/access',
          authKey: 'IntUsrAccCtrlMgmt',
          async action(context: any) {
            const AccessControl = loadable(
              () => import(/* webpackChunkName: "pages-AccessControl" */ 'pages/AccessControl'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await accessControlResolver(props, context);
            } else if (!context.skipResolver) {
              accessControlResolver(props, context);
            }

            return <AccessControl {...context} />;
          },
        },
        {
          path: '/access/:userUuid',
          authKey: 'IntUsrAccCtrlMgmt',
          async action(context: any) {
            const AccessControlCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-AccessControlCreate" */ 'pages/AccessControlCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await accessControlDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await accessControlDetailResolver(props, context);
            }

            return <AccessControlCreate {...context} />;
          },
        },
        {
          path: '/alias',
          authKey: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
          async action(context: any) {
            const Aliases = loadable(
              () => import(/* webpackChunkName: "pages-Aliases" */ 'pages/Aliases'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await aliasResolver(props, context);
            } else if (!context.skipResolver) {
              aliasResolver(props, context);
            }

            return <Aliases {...context} />;
          },
        },
        {
          path: '/alias/create',
          authKey: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
          isCreate: true,
          async action(context: any) {
            const Aliases = loadable(
              () => import(/* webpackChunkName: "pages-AliasCreate" */ 'pages/AliasCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await aliasCreateResolver(props, context);
            } else if (!context.skipResolver) {
              await aliasCreateResolver(props, context);
            }

            return <Aliases {...context} />;
          },
        },
        {
          path: '/alias/:aliasId',
          authKey: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
          async action(context: any) {
            const Aliases = loadable(
              () => import(/* webpackChunkName: "pages-AliasCreate" */ 'pages/AliasCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await aliasCreateResolver(props, context);
            } else if (!context.skipResolver) {
              await aliasCreateResolver(props, context);
            }

            return <Aliases {...context} />;
          },
        },
        {
          path: '/channelManagement',
          authKey: 'ChanlMgmt',
          children: [
            {
              path: '',
              authKey: 'ChanlMgmt',
              async action(context: any) {
                const ChannelMangerManagementList = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ChannelMangerManagementList" */ 'pages/ChannelMangerManagementList'
                    ),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await channelMangerManagementListResolver(props, context.query || {});
                } else if (!context.skipResolver) {
                  channelMangerManagementListResolver(props, context.query || {});
                }
                return <ChannelMangerManagementList {...context} />;
              },
            },
            {
              path: '/create',
              authKey: 'ChanlMgmt',
              isCreate: true,
              async action(context: any) {
                const ChannelManageManagementEdit = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ChannelManagerManagementEdit" */ 'pages/ChannelManagerManagementEdit'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );

                return <ChannelManageManagementEdit {...context} />;
              },
            },
            {
              path: '/edit/:channelManagementId',
              authKey: 'ChanlMgmt',
              isCreate: false,
              async action(context: any) {
                const ChannelManageManagementEdit = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-ChannelManagerManagementEdit" */ 'pages/ChannelManagerManagementEdit'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );

                if (isServer) {
                  await channelManagerManagementEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await channelManagerManagementEditResolver(props, context);
                }
                return <ChannelManageManagementEdit {...context} />;
              },
            },
            {
              path: '/edit/:channelManagementId/providers',
              authKey: 'ChanlMgmt',
              async action(context: any) {
                const ChannelMangerProviderList = loadable(() =>
                  import(
                    /* webpackChunkName: "pages-ChannelManagerProviderList" */ 'pages/ChannelManagerProviderList'
                  ),
                );

                if (isServer) {
                  await channelManagerProviderListResolver(props, context);
                } else if (!context.skipResolver) {
                  await channelManagerProviderListResolver(props, context);
                }

                return <ChannelMangerProviderList {...context} />;
              },
            },
          ],
        },
        {
          path: '/distributorManagement',
          authKey: 'DistrMgmt',
          children: [
            {
              path: '',
              authKey: 'DistrMgmt',
              async action(context: any) {
                const DistributorManagementList = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-DistributorManagementList" */ 'pages/DistributorManagementList'
                    ),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await channelManagerDistributorListResolver(props, context.query);
                } else if (!context.skipResolver) {
                  channelManagerDistributorListResolver(props, context.query);
                }

                return <DistributorManagementList {...context} />;
              },
            },
            {
              path: '/create',
              authKey: 'DistrMgmt',
              isCreate: true,
              async action(context: any) {
                const DistributorManagementEdit = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-DistributorManagementEdit" */ 'pages/DistributorManagementEdit'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );

                if (isServer) {
                  await channelManagerDistributorEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await channelManagerDistributorEditResolver(props, context);
                }

                return <DistributorManagementEdit {...context} />;
              },
            },
            {
              path: '/edit/:distributorId',
              authKey: 'DistrMgmt',
              isCreate: false,
              async action(context: any) {
                const DistributorManagementEdit = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-DistributorManagementEdit" */ 'pages/DistributorManagementEdit'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );

                if (isServer) {
                  await channelManagerDistributorEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await channelManagerDistributorEditResolver(props, context);
                }

                return <DistributorManagementEdit {...context} />;
              },
            },
          ],
        },
        {
          path: '/faxManagement',
          authKey: 'FaxMgmt',
          async action(context: any) {
            const FaxManagement = loadable(
              () => import(/* webpackChunkName: "pages-FaxManagement" */ 'pages/FaxManagement'),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );

            if (isServer) {
              await faxManagementResolver(props, context);
            } else if (!context.skipResolver) {
              await faxManagementResolver(props, context);
            }

            return <FaxManagement {...context} />;
          },
        },
        {
          path: '/campaigns',
          authKey: 'SpoSlsPromoMgmt',
          async action(context: any) {
            const CampaignList = loadable(
              () => import(/* webpackChunkName: "pages-Campaign" */ 'pages/Campaign'),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );
            if (isServer) {
              await campaignListResolver(props, context);
            } else if (!context.skipResolver) {
              campaignListResolver(props, context);
            }
            return <CampaignList {...context} />;
          },
        },
        {
          path: '/campaigns/create',
          authKey: 'SpoSlsPromoMgmt',
          isCreate: true,
          async action(context: any) {
            const CampaignCreate = loadable(
              () => import(/* webpackChunkName: "pages-CampaignCreate" */ 'pages/CampaignCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await campaignResolver(props, context);
            } else if (!context.skipResolver) {
              await campaignResolver(props, context);
            }
            return <CampaignCreate {...context} />;
          },
        },
        {
          path: '/campaigns/:campaignId',
          authKey: 'SpoSlsPromoMgmt',
          async action(context: any) {
            const CampaignCreate = loadable(
              () => import(/* webpackChunkName: "pages-CampaignCreate" */ 'pages/CampaignCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await campaignResolver(props, context);
            } else if (!context.skipResolver) {
              await campaignResolver(props, context);
            }
            return <CampaignCreate {...context} />;
          },
        },
        {
          path: '/visualContentGroup',
          authKey: 'VisConGrpMgmt',
          async action(context: any) {
            const VisualContentGroupList = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-VisualContentGroupList" */ 'pages/VisualContentGroupList'
                ),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} isNarrow={true} />,
              },
            );

            if (isServer) {
              await visualContentGroupListResolver(
                props,
                context.query || {},
                context.params || {},
              );
            } else if (!context.skipResolver) {
              await visualContentGroupListResolver(
                props,
                context.query || {},
                context.params || {},
              );
            }

            return <VisualContentGroupList {...context} />;
          },
        },
        {
          path: '/visualContentGroup/create',
          authKey: 'VisConGrpMgmt',
          isCreate: true,
          async action(context: any) {
            const VisualContentGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-VisualContentGroupCreate" */ 'pages/VisualContentGroupCreate'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );

            if (isServer) {
              await visualContentGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await visualContentGroupDetailResolver(props, context);
            }

            return <VisualContentGroupDetail {...context} />;
          },
        },
        {
          path: '/visualContentGroup/:visualContentGroupId',
          authKey: 'VisConGrpMgmt',
          async action(context: any) {
            const VisualContentGroupDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-VisualContentGroupCreate" */ 'pages/VisualContentGroupCreate'
                ),
              { fallback: <SkeletonPage pageType={'detail'} /> },
            );

            if (isServer) {
              await visualContentGroupDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await visualContentGroupDetailResolver(props, context);
            }

            return <VisualContentGroupDetail {...context} />;
          },
        },
        {
          path: '/labelManagement',
          authKey: 'ExtrntLblMgmt',
          children: [
            {
              path: '',
              authKey: 'ExtrntLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementPageList" */ 'pages/LabelManagementPageList'
                    ),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query);
                }
                return <LabelManagementPageList {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'ExtrntLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementPageDetail" */ 'pages/LabelManagementPageDetail'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail
                    {...context}
                    isEdit={false}
                    product={Products.EXTRNT}
                  />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'ExtrntLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementPageDetail" */ 'pages/LabelManagementPageDetail'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }

                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'ExtrntLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'ExtrntLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeList" */ 'pages/LabelManagementLabeList'
                        ),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'ExtrntLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeDetail'
                        ),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'ExtrntLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeDetail'
                        ),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/travelerLabelManagement',
          authKey: 'TvlrLblMgmt',
          children: [
            {
              path: '',
              authKey: 'TvlrLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementPageList'
                    ),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.TRVLR);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.TRVLR);
                }

                return <LabelManagementPageList product={Products.TRVLR} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'TvlrLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementPageDetail'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail {...context} isEdit={false} product={Products.TRVLR} />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'TvlrLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementPageDetail'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'TvlrLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'TvlrLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeList'
                        ),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'TvlrLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeDetail'
                        ),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'TvlrLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeDetail'
                        ),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/seoLabelManagement',
          authKey: 'SeoLblMgmt',
          children: [
            {
              path: '',
              authKey: 'SeoLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementPageList'
                    ),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.SEO);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.SEO);
                }

                return <LabelManagementPageList product={Products.SEO} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'SeoLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementPageDetail'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail {...context} isEdit={false} product={Products.SEO} />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'SeoLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementPageDetail'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'SeoLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'SeoLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeList'
                        ),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'SeoLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeDetail'
                        ),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'SeoLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () =>
                        import(
                          /* webpackChunkName: "pages-LabelManagementLabeDetail" */ 'pages/LabelManagementLabeDetail'
                        ),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/tvlrCommonLabelManagement',
          authKey: 'TvlrCommonLblMgmt',
          children: [
            {
              path: '',
              authKey: 'TvlrCommonLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () => import('pages/LabelManagementPageList'),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.TVLRCOM);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.TVLRCOM);
                }

                return <LabelManagementPageList product={Products.TVLRCOM} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'TvlrCommonLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail
                    {...context}
                    isEdit={false}
                    product={Products.TVLRCOM}
                  />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'TvlrCommonLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'TvlrCommonLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'TvlrCommonLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () => import('pages/LabelManagementLabeList'),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'TvlrCommonLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'TvlrCommonLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/dpLabelManagement',
          authKey: 'DPLblMgmt',
          children: [
            {
              path: '',
              authKey: 'DPLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () => import('pages/LabelManagementPageList'),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.DP);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.DP);
                }

                return <LabelManagementPageList product={Products.DP} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'DPLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail {...context} isEdit={false} product={Products.DP} />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'DPLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'DPLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'DPLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () => import('pages/LabelManagementLabeList'),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'DPLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'DPLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/myPageLabelManagement',
          authKey: 'MyPageLblMgmt',
          children: [
            {
              path: '',
              authKey: 'MyPageLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () => import('pages/LabelManagementPageList'),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.MYPAGE);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.MYPAGE);
                }

                return <LabelManagementPageList product={Products.MYPAGE} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'MyPageLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail
                    {...context}
                    isEdit={false}
                    product={Products.MYPAGE}
                  />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'MyPageLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'MyPageLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'MyPageLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () => import('pages/LabelManagementLabeList'),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'MyPageLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'MyPageLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/rentalCarLabelManagement',
          authKey: 'RentalCarLblMgmt',
          children: [
            {
              path: '',
              authKey: 'RentalCarLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () => import('pages/LabelManagementPageList'),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.RENTALCAR);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.RENTALCAR);
                }

                return <LabelManagementPageList product={Products.RENTALCAR} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'RentalCarLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail
                    {...context}
                    isEdit={false}
                    product={Products.RENTALCAR}
                  />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'RentalCarLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'RentalCarLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'RentalCarLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () => import('pages/LabelManagementLabeList'),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'RentalCarLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'RentalCarLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/travelAppLabelManagement',
          authKey: 'TravelAppLblMgmt',
          children: [
            {
              path: '',
              authKey: 'TravelAppLblMgmt',
              async action(context: any) {
                const LabelManagementPageList = loadable(
                  () => import('pages/LabelManagementPageList'),
                  { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                );

                if (isServer) {
                  await pagesListResolver(props, context.query, Products.TRAVELAPP);
                } else if (!context.skipResolver) {
                  pagesListResolver(props, context.query, Products.TRAVELAPP);
                }

                return <LabelManagementPageList product={Products.TRAVELAPP} {...context} />;
              },
            },
            {
              path: '/createPage',
              authKey: 'TravelAppLblMgmt',
              isCreate: true,
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                return (
                  <LabelManagementPageDetail
                    {...context}
                    isEdit={false}
                    product={Products.TRAVELAPP}
                  />
                );
              },
            },
            {
              path: '/:pageId',
              authKey: 'TravelAppLblMgmt',
              async action(context: any) {
                const LabelManagementPageDetail = loadable(
                  () => import('pages/LabelManagementPageDetail'),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );
                if (isServer) {
                  await pageEditResolver(props, context);
                } else if (!context.skipResolver) {
                  await pageEditResolver(props, context);
                }
                return <LabelManagementPageDetail {...context} isEdit />;
              },
            },
            {
              path: '/:pageId/labels',
              authKey: 'TravelAppLblMgmt',
              children: [
                {
                  path: '',
                  authKey: 'TravelAppLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabeList = loadable(
                      () => import('pages/LabelManagementLabeList'),
                      { fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} /> },
                    );
                    if (isServer) {
                      await labelListResolver(props, context.params.pageId, context.query);
                    } else if (!context.skipResolver) {
                      labelListResolver(props, context.params.pageId, context.query);
                    }
                    return <LabelManagementLabeList {...context} />;
                  },
                },
                {
                  path: '/createLabel',
                  authKey: 'TravelAppLblMgmt',
                  isCreate: true,
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );
                    return <LabelManagementLabelDetail {...context} isEdit={false} />;
                  },
                },
                {
                  path: '/:labelId',
                  authKey: 'TravelAppLblMgmt',
                  async action(context: any) {
                    const LabelManagementLabelDetail = loadable(
                      () => import('pages/LabelManagementLabeDetail'),
                      { fallback: <SkeletonPage pageType={'detail'} /> },
                    );

                    if (isServer) {
                      await labelDetailResolver(props, context.params);
                    } else if (!context.skipResolver) {
                      await labelDetailResolver(props, context.params);
                    }
                    return <LabelManagementLabelDetail {...context} isEdit />;
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/application-tracker/:campaignId',
          authKey: 'SpoSlsPromoMgmt',
          children: [
            {
              path: '/list',
              authKey: 'SpoSlsPromoMgmt',
              async action(context: any) {
                const ApplicationTrackerList = loadable(() =>
                  import(
                    /* webpackChunkName: "pages-ApplicationTrackerList" */ 'pages/ApplicationTrackerList'
                  ),
                );

                if (isServer) {
                  await applicationTrackerResolver(props, context);
                } else if (!context.skipResolver) {
                  await applicationTrackerResolver(props, context);
                }

                return <ApplicationTrackerList {...context} />;
              },
            },
            {
              path: '/details/:applicationId',
              authKey: 'SpoSlsPromoMgmt',
              async action(context: any) {
                const ExtraNetApplicationDetail = loadable(() =>
                  import(
                    /* webpackChunkName: "pages-ApplicationDetail" */ 'pages/ApplicationDetail'
                  ),
                );

                if (isServer) {
                  await extranetApplicationDetailResolver(props, context);
                } else if (!context.skipResolver) {
                  await extranetApplicationDetailResolver(props, context);
                }
                return <ExtraNetApplicationDetail {...context} />;
              },
            },
          ],
        },
        {
          path: '/media',
          authKey: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
          async action(context: any) {
            const MediaList = loadable(
              () => import(/* webpackChunkName: "pages-MediaList" */ 'pages/MediaList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasMedia={true} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await mediaListResolver(props, context);
            } else if (!context.skipResolver) {
              await mediaListResolver(props, context);
            }

            return <MediaList {...context} />;
          },
        },
        {
          path: '/media/create',
          authKey: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
          isCreate: true,
          async action(context: any) {
            const MediaDetail = loadable(
              () => import(/* webpackChunkName: "pages-MediaDetail" */ 'pages/MediaDetail'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await mediaDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await mediaDetailResolver(props, context);
            }

            return <MediaDetail {...context} />;
          },
        },
        {
          path: '/media/:mediaId',
          authKey: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
          async action(context: any) {
            const MediaDetail = loadable(
              () => import(/* webpackChunkName: "pages-MediaDetail" */ 'pages/MediaDetail'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );
            if (isServer) {
              await mediaDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await mediaDetailResolver(props, context);
            }
            return <MediaDetail {...context} />;
          },
        },
        {
          path: '/filterGroup',
          authKey: SITE_MENU_MAP.CONSUMER_SITE_FILTER_GROUP_MANAGEMENT.authKey,
          async action(context: any) {
            const FilterGroups = loadable(
              () => import(/* webpackChunkName: "pages-FilterGroups" */ 'pages/FilterGroups'),
              {
                fallback: <SkeletonPage pageType={'list'} isNarrow={false} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await filterGroupResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              filterGroupResolver(props, context.query || {});
            }

            return <FilterGroups {...context} />;
          },
        },
        {
          path: '/filterGroup/create',
          authKey: SITE_MENU_MAP.CONSUMER_SITE_FILTER_GROUP_MANAGEMENT.authKey,
          isCreate: true,
          async action(context: any) {
            const FilterGroupForm = loadable(
              () =>
                import(/* webpackChunkName: "pages-FilterGroupCreate" */ 'pages/FilterGroupCreate'),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await filterGroupCreateResolver(props, context);
            } else if (!context.skipResolver) {
              await filterGroupCreateResolver(props, context);
            }

            return <FilterGroupForm {...context} />;
          },
        },
        {
          path: '/filterGroup/:id',
          authKey: SITE_MENU_MAP.CONSUMER_SITE_FILTER_GROUP_MANAGEMENT.authKey,
          async action(context: any) {
            const FilterGroupForm = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-FilterGroupCreate  " */ 'pages/FilterGroupCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await filterGroupCreateResolver(props, context);
            } else if (!context.skipResolver) {
              await filterGroupCreateResolver(props, context);
            }

            return <FilterGroupForm {...context} />;
          },
        },
        {
          path: '/filterComponent',
          authKey: SITE_MENU_MAP.CONSUMER_SITE_FILTER_COMPONENT_MANAGEMENT.authKey,
          async action(context: any) {
            const FilterComponents = loadable(
              () =>
                import(/* webpackChunkName: "pages-FilterComponents" */ 'pages/FilterComponents'),
              {
                fallback: <SkeletonPage pageType={'list'} isNarrow={true} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await filterComponentResolver(props, context.query || {});
            } else if (!context.skipResolver) {
              filterComponentResolver(props, context.query || {});
            }

            return <FilterComponents {...context} />;
          },
        },
        {
          path: '/filterComponent/:id',
          authKey: SITE_MENU_MAP.CONSUMER_SITE_FILTER_COMPONENT_MANAGEMENT.authKey,
          async action(context: any) {
            const FilterComponentForm = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-FilterComponentForm" */ 'pages/FilterComponentForm'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await filterComponentFormResolver(props, context);
            } else if (!context.skipResolver) {
              await filterComponentFormResolver(props, context);
            }

            return <FilterComponentForm {...context} />;
          },
        },
        {
          path: '/notifications',
          authKey: 'Notification',
          async action(context: any) {
            const NotificationInbox = loadable(
              () =>
                import(/* webpackChunkName: "pages-NotificationInbox" */ 'pages/NotificationInbox'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await notificationInboxResolver(props, context);
            } else if (!context.skipResolver) {
              notificationInboxResolver(props, context);
            }

            return <NotificationInbox {...context} />;
          },
        },
        // TEQB-4838: Internal Tool's Notification Sentbox should not allow access to Create page
        // {
        //   path: '/notifications/create',
        //   authKey: 'Notification',
        //   isCreate: true,
        //   async action(context: any) {
        //     const NotificationDetail = loadable(() => import('pages/NotificationDetail'), {
        //       fallback: <SkeletonPage pageType={'detail'} />,
        //     });

        //     if (isServer) {
        //       await notificationDetailResolver(props, context);
        //     } else if (!context.skipResolver) {
        //       await notificationDetailResolver(props, context);
        //     }

        //     return <NotificationDetail {...context} />;
        //   },
        // },
        {
          path: '/notifications/:notificationId',
          authKey: 'Notification',
          async action(context: any) {
            const NotificationDetail = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-NotificationDetail" */ 'pages/NotificationDetail'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await notificationDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await notificationDetailResolver(props, context);
            }

            return <NotificationDetail {...context} />;
          },
        },
        {
          path: '/announcement',
          authKey: 'Announcement',
          async action(context: any) {
            const AnnouncementList = loadable(
              () =>
                import(/* webpackChunkName: "pages-AnnouncementList" */ 'pages/AnnouncementList'),
              {
                fallback: <SkeletonPage pageType={'list'} hasCheckbox={false} />,
              },
            );

            if (isServer) {
              await announcementListResolver(props, context);
            } else if (!context.skipResolver) {
              announcementListResolver(props, context);
            }

            return <AnnouncementList {...context} />;
          },
        },
        {
          path: '/announcement/create',
          authKey: 'Announcement',
          isCreate: true,
          async action(context: any) {
            const AnnouncementCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-AnnouncementCreate" */ 'pages/AnnouncementCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await announcementDetailResolver(props, context);
            } else if (!context.skipResolver) {
              announcementDetailResolver(props, context);
            }

            return <AnnouncementCreate {...context} />;
          },
        },
        {
          path: '/announcement/:announcementId',
          authKey: 'Announcement',
          async action(context: any) {
            const AnnouncementCreate = loadable(
              () =>
                import(
                  /* webpackChunkName: "pages-AnnouncementCreate" */ 'pages/AnnouncementCreate'
                ),
              {
                fallback: <SkeletonPage pageType={'detail'} />,
              },
            );

            if (isServer) {
              await announcementDetailResolver(props, context);
            } else if (!context.skipResolver) {
              announcementDetailResolver(props, context);
            }

            return <AnnouncementCreate {...context} />;
          },
        },
        {
          path: '/manualTranslation',
          authKey: 'TranslationMgmt',
          async action(context: any) {
            const ManualTranslation = loadable(() =>
              import(/* webpackChunkName: "pages-ManualTranslation" */ 'pages/ManualTranslation'),
            );

            if (isServer) {
              await manualTranslationResolver(props);
            } else if (!context.skipResolver) {
              await manualTranslationResolver(props);
            }
            return <ManualTranslation {...context} />;
          },
        },
        {
          path: '/reviews',
          authKey: 'RevEx',
          async action(context: any) {
            const ReviewExamList = loadable(
              () => import(/* webpackChunkName: "pages-ReviewExamList" */ 'pages/ReviewExamList'),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );
            if (isServer) {
              await reviewExamListResolver(props, context);
            } else if (!context.skipResolver) {
              await reviewExamListResolver(props, context);
            }
            return <ReviewExamList {...context} />;
          },
        },
        {
          path: '/reviews/:reviewId',
          authKey: 'RevEx',
          async action(context: any) {
            const ReviewExamDetail = loadable(() =>
              import(/* webpackChunkName: "pages-ReviewExamDetail" */ 'pages/ReviewExamDetail'),
            );

            if (isServer) {
              await reviewExamDetailResolver(props, context);
            } else if (!context.skipResolver) {
              await reviewExamDetailResolver(props, context);
            }
            return <ReviewExamDetail {...context} />;
          },
        },
        {
          path: '/reviews/:reviewId/history',
          authKey: 'RevEx',
          async action(context: any) {
            const ReviewExamHistory = loadable(() =>
              import(/* webpackChunkName: "pages-ReviewExamHistory" */ 'pages/ReviewExamHistory'),
            );

            if (isServer) {
              await reviewExamHistoryResolver(props, context);
            } else if (!context.skipResolver) {
              await reviewExamHistoryResolver(props, context);
            }
            return <ReviewExamHistory {...context} />;
          },
        },
        {
          path: '/luxuryInvitation',
          authKey: 'LuxuryInvitation',
          async action(context: any) {
            const LuxuryInvitation = loadable(() =>
              import(/* webpackChunkName: "pages-LuxuryInvitation" */ 'pages/LuxuryInvitation'),
            );
            if (isServer) {
              await luxuryInvitationResolver(props);
            } else if (!context.skipResolver) {
              await luxuryInvitationResolver(props);
            }
            return <LuxuryInvitation {...context} />;
          },
        },
        {
          path: '/userSegment',
          authKey: 'UserSegMgmt',
          async action(context: any) {
            const UserSegment = loadable(
              () => import(/* webpackChunkName: "pages-UserSegment" */ 'pages/UserSegment'),
              {
                fallback: <SkeletonPage pageType={'table'} />,
              },
            );
            if (isServer) {
              await userSegmentResolver(props, context);
            } else if (!context.skipResolver) {
              userSegmentResolver(props, context);
            }
            return <UserSegment {...context} />;
          },
        },
        {
          path: '/objectScore',
          authKey: SITE_MENU_MAP.OBJECT_SCORE.authKey,
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const ObjectScore = loadable(
              () => import(/* webpackChunkName: "pages-ObjectScore" */ 'pages/ObjectScore'),
              {
                fallback: <SkeletonPage pageType={'filter'} />,
              },
            );

            if (isServer) {
              await objectScoreResolver(props, context);
            } else if (!context.skipResolver) {
              objectScoreResolver(props, context);
            }

            return <ObjectScore {...context} />;
          },
        },
        {
          path: '/certifications',
          authKey: 'CertificationManagement',
          isDataInputLanguageAllowed: true,
          async action(context: any) {
            const Certifications = loadable(
              () => import(/* webpackChunkName: "pages-Certifications" */ 'pages/Certifications'),
              {
                fallback: <SkeletonPage pageType={'filter'} />,
              },
            );

            return <Certifications {...context} />;
          },
        },
        {
          path: '/providerDataTranslation',
          authKey: SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.authKey,
          children: [
            {
              path: '',
              authKey: SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.authKey,
              async action(context: any) {
                const MultiManualTranslation = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-MultiManualTranslation" */ 'pages/MultiManualTranslation'
                    ),
                  {
                    fallback: <SkeletonPage pageType={'filter'} />,
                  },
                );

                if (isServer) {
                  await multiManualTranslationResolver(props, context);
                } else if (!context.skipResolver) {
                  multiManualTranslationResolver(props, context);
                }
                return <MultiManualTranslation {...context} />;
              },
            },
            {
              path: '/upload',
              authKey: SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.authKey,
              async action(context: any) {
                const MultiManualTranslationUpload = loadable(
                  () =>
                    import(
                      /* webpackChunkName: "pages-MultiManualTranslationUpload" */ 'pages/MultiManualTranslationUpload'
                    ),
                  { fallback: <SkeletonPage pageType={'detail'} /> },
                );

                if (isServer) {
                  await multiManualTranslationUploadResolver(props, context);
                } else if (!context.skipResolver) {
                  multiManualTranslationUploadResolver(props, context);
                }
                return <MultiManualTranslationUpload {...context} />;
              },
            },
          ],
        },
        {
          path: '/seoManagement',
          authKey: SITE_MENU_MAP.SEO_MANAGEMENT.authKey,
          async action(context: any) {
            const SEOManagement = loadable(() =>
              import(/* webpackChunkName: "pages-SEOManagement" */ 'pages/SEOManagement'),
            );
            return <SEOManagement {...context} />;
          },
        },
      ],
    },
  ];
};
