import React, { ChangeEvent } from 'react';
import { cx } from '@travel/utils';
import Balloon, { ArrowDirection } from '../Balloon';
import RadioButtonGroup from '../RadioButtonGroup';
import { Props as RadioButtonProps } from '../RadioButton';

import styles from './radioBalloon.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Label for the dropdown */
  label?: React.ReactNode;
  /** List of options */
  options: Array<RadioButtonProps>;
  /** default selected option */
  defaultSelectedOption?: string;
  /** arrow direction */
  direction?: ArrowDirection;
  /** On select handler */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

function RadioBalloon(props: Props) {
  const { className, label, options, defaultSelectedOption, direction, onChange, ...rest } = props;

  return (
    <Balloon
      className={cx(className, styles.container)}
      direction={direction}
      hasContentMinHeight={false}
      data-testid="radioBalloon-wrapper"
      {...rest}
    >
      <div className={styles.label} data-testid="radioBalloon-label">
        {label}
      </div>
      <RadioButtonGroup
        defaultSelectedOption={defaultSelectedOption}
        onChange={onChange}
        options={options}
        data-testid="radioBalloon-radioGroup"
      />
    </Balloon>
  );
}

export default RadioBalloon;
