import React, { ReactNode, SyntheticEvent, ChangeEvent, useState } from 'react';
import { cx, isNotEmptyArray, ariaOnKeyDown } from '@travel/utils';
import styles from './ballonDropdown.module.scss';

import Balloon, { ArrowDirection } from '../Balloon';
import Correct from '@travel/icons/ui/Correct';
import { Search } from '@travel/icons/ui';
import { getTranslation } from '@travel/translation';
import TextField from '../TextField';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** List to be displayed on the dropdown */
  options?: Array<{
    text: ReactNode;
    value: string;
  }>;
  /** Default value*/
  defaultValue?: string;
  /** arrow direction */
  direction?: ArrowDirection;
  /** search keyword */
  keyword?: string;
  /** Callback function when value is selected */
  onChange?: (stateValue: string) => void;
  /** Callback function to search options*/
  onSearch?: (keyword: string) => void;
  /** Callback function to clear search*/
  onClear?: () => void;
};

function BallonDropdown(props: Props) {
  const {
    className,
    options = [],
    defaultValue = '',
    direction,
    keyword,
    onChange,
    onSearch,
    onClear,
    ...rest
  } = props;
  const [stateValue, setStateValue] = useState(defaultValue);

  const handleOnClick = (e: SyntheticEvent<HTMLElement>) => {
    const target = e.currentTarget as HTMLElement;
    const selectedOption = options.find(option => option.value === target.id);
    setStateValue((selectedOption && selectedOption.value) || '');
    onChange && onChange((selectedOption && selectedOption.value) || '');
  };

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch && onSearch(event.target.value);
  };

  return (
    <Balloon
      direction={direction}
      hasContentMinHeight={false}
      className={cx(className, styles.balloonMenu)}
      {...rest}
    >
      {onSearch && (
        <div className={styles.searchWrapper}>
          <TextField
            value={keyword}
            inputProps={{
              placeholder: getTranslation({ id: 'Extranet_Header.Search_Instruction' }).join(''),
            }}
            onChange={handleOnSearch}
            onClear={onClear}
            data-testid="balloonDropdown-search"
          ></TextField>
          {!keyword && <Search className={styles.icon} data-testid="balloonDropdown-search-icon" />}
        </div>
      )}
      <ul className={styles.content} data-testid="balloonDropDown-list-ul">
        {isNotEmptyArray(options) &&
          options.map(item => {
            const activeClassName = stateValue === item.value ? styles.active : '';

            return (
              <li
                id={item.value}
                key={item.value}
                onClick={handleOnClick}
                className={activeClassName}
                data-testid={item.value}
                tabIndex={0}
                onKeyDown={ariaOnKeyDown(handleOnClick)}
              >
                {item.text}
                {activeClassName && <Correct size={24} />}
              </li>
            );
          })}
      </ul>
    </Balloon>
  );
}

export default BallonDropdown;
