import React from 'react';

import { getTranslation, Translate } from '@travel/translation';
import isNotEmptyArray from '@travel/ui/utils/arrays';

import cloneDeep from 'lodash/cloneDeep';

import { Option } from 'constants/promotion';

export const CONDITION_VALUES = {
  BROAD: 'BROAD',
  PHRASE: 'PHRASE',
  EXACT: 'EXACT',
} as const;

export type Condition = typeof CONDITION_VALUES[keyof typeof CONDITION_VALUES];

export const PERIOD_TYPE_VALUES: {
  [key: string]: string;
} = {
  stay: 'stayingPeriod',
  checkin: 'checkinDates',
  book: 'bookingDates',
  cancel: 'cancelledDates',
};

export const SEARCH_CONDITIONS = [
  {
    label: <Translate id="Supplier_Enumeration.Search_Type.Broad_Match" />,
    name: 'broad',
    value: 'BROAD',
  },
  {
    label: <Translate id="Supplier_Enumeration.Search_Type.Phrase_Match" />,
    name: 'phrase',
    value: 'PHRASE',
  },
  {
    label: <Translate id="Supplier_Enumeration.Search_Type.Exact_Match" />,
    name: 'exact',
    value: 'EXACT',
  },
];

export const PERIOD_TYPE_LABEL_ID = [
  {
    labelId: 'booking_management_fundamental.list.form.staying_date',
    value: PERIOD_TYPE_VALUES['stay'],
  },
  {
    labelId: 'booking_management_fundamental.list.form.checkin_date',
    value: PERIOD_TYPE_VALUES['checkin'],
  },
  {
    labelId: 'booking_management_fundamental.list.form.booking_date',
    value: PERIOD_TYPE_VALUES['book'],
  },
  {
    labelId: 'booking_management_fundamental.list.form.cancellation_date',
    value: PERIOD_TYPE_VALUES['cancel'],
  },
];

export const PERIOD_TYPE_CONDITIONS = {
  options: PERIOD_TYPE_LABEL_ID.map(option => ({
    label: <Translate id={option.labelId} />,
    value: option.value,
    name: option.value,
  })),
  defaultSelectedValue: 'stayingPeriod',
};

export const periodTypeToQuery: { [key: string]: { [key: string]: string } } = {
  stayingPeriod: {
    fromField: 'travelingPeriodFrom',
    toField: 'travelingPeriodTo',
  },
  bookingDates: {
    fromField: 'bookingPeriodFrom',
    toField: 'bookingPeriodTo',
  },
  cancelledDates: {
    fromField: 'canceledPeriodFrom',
    toField: 'canceledPeriodTo',
  },
  checkinDates: {
    fromField: 'checkInPeriodFrom',
    toField: 'checkInPeriodTo',
  },
};

export type TravellingPeriod = {
  fromDate: string;
  toDate: string;
  travelOnSunday: boolean;
  travelOnMonday: boolean;
  travelOnTuesday: boolean;
  travelOnWednesday: boolean;
  travelOnThursday: boolean;
  travelOnFriday: boolean;
  travelOnSaturday: boolean;
};

export const getDayOfWeekDetails = (travellingPeriod: TravellingPeriod) => {
  const newArr: any[] = [];
  if (travellingPeriod?.travelOnSunday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Sun' }));
  }
  if (travellingPeriod?.travelOnMonday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Mon' }));
  }
  if (travellingPeriod?.travelOnTuesday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Tue' }));
  }
  if (travellingPeriod?.travelOnWednesday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Wed' }));
  }
  if (travellingPeriod?.travelOnThursday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Thu' }));
  }
  if (travellingPeriod?.travelOnFriday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Fri' }));
  }
  if (travellingPeriod?.travelOnSaturday) {
    newArr.push(getTranslation({ id: 'Common.Calendar.Sat' }));
  }
  return isNotEmptyArray(newArr) && newArr.join(', ');
};

export const turnIntoOptions = (
  objectOptions: TravellingPeriod,
  staticOptions: Option[],
): Option[] => {
  const options: Option[] = [];
  const { fromDate, toDate, ...objOpt } = objectOptions;
  const newStaticOpt = cloneDeep(staticOptions);

  newStaticOpt.forEach(option => {
    for (const [key, value] of Object.entries(objOpt)) {
      if (key === option.value) {
        option.isCheck = Boolean(value);
      }
    }
    options.push(option);
  });
  return options;
};
