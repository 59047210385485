import { ComponentType, ReactNode } from 'react';

import env from '@travel/env';
import { City } from '@travel/icons/service';
import {
  AccessManagement,
  AssetControl,
  BookingManagement,
  CouponVariant,
  EditVariant,
  ELearningComputer,
  FinanceManagement,
  HomeVariant,
  Labels,
  MasterAssets,
  NotificationVariant,
  Photo,
  Question,
  UserReviewStar,
} from '@travel/icons/ui';

import { PROVIDER_DESIGNATION } from 'constants/provider';
import SITE_MENU, { SITE_MENU_MAP } from 'constants/site-menu';
import { DESIGNATION } from './designations';

export type SubMenu = {
  title: ReactNode;
  link: string;
  key: string;
  isDisabled?: boolean;
};

export type Menu = {
  title: ReactNode;
  icon: ComponentType<{ className: string; color?: any; size?: number }>;
  link?: string;
  subMenu?: SubMenu[];
  isDisabled?: boolean;
  order?: number;
  key: string;
  class?: string;
};

export type LinkItem = {
  title: ReactNode;
  icon: ComponentType<{ className: string; color?: any; size?: number }>;
  link: string;
  key: string;
};

/** NOTE: Let's define authKey and label in SITE_MENU_MAP to keep consistency. */
export const providerMenus = [
  {
    title: SITE_MENU.Home,
    icon: HomeVariant,
    link: '/',
    key: 'Home',
    class: 'fullPathFill',
  },
  {
    title: SITE_MENU.ProvInfoSec,
    icon: MasterAssets,
    key: 'ProviderInformation',
    class: 'allButFirstPathFill',
    isDisabled: false,
    subMenu: [
      {
        title: SITE_MENU.ProvInfoMgmt,
        key: 'ProvInfoMgmt',
        link: '/provider',
      },
      {
        title: SITE_MENU_MAP.CANCELLATION_POLICY.label,
        key: SITE_MENU_MAP.CANCELLATION_POLICY.authKey,
        link: '/cancellationPolicy',
      },
    ],
  },
  {
    title: SITE_MENU.PhotoVideoSec,
    icon: Photo,
    key: 'Photo/VideoManagement',
    class: 'innerGraphicsFill',
    isDisabled: false,
    subMenu: [
      {
        title: SITE_MENU.VisConGrpMgmtEx,
        key: 'VisConGrpMgmt',
        link: '/visualContentGroup',
      },
      {
        title: SITE_MENU_MAP.MEDIA_MANAGEMENT.label,
        key: SITE_MENU_MAP.MEDIA_MANAGEMENT.authKey,
        link: '/media',
      },
      {
        title: SITE_MENU_MAP.INTRODUCTION_CONTENT.label,
        key: SITE_MENU_MAP.INTRODUCTION_CONTENT.authKey,
        link: '/introductionContents',
        displayOnDesignation: PROVIDER_DESIGNATION.LUXURY,
      },
      {
        title: SITE_MENU_MAP.HIGHLIGHT_CONTENT.label,
        key: SITE_MENU_MAP.HIGHLIGHT_CONTENT.authKey,
        link: '/highlightContents',
        displayOnDesignation: PROVIDER_DESIGNATION.STANDARD,
      },
    ],
  },
  {
    title: SITE_MENU.ItemPlanSec,
    icon: AssetControl,
    key: 'Item/PlanManagement',
    class: 'innerGraphicsFill',
    isDisabled: false,
    subMenu: [
      {
        title: SITE_MENU.ItemMgmt,
        key: 'ItemMgmt',
        link: '/items',
      },
      {
        title: SITE_MENU.RatePlanMgmt,
        key: 'RatePlanMgmt',
        link: '/ratePlans',
      },

      {
        title: SITE_MENU.PlanMgmt,
        key: 'PlanMgmt',
        link: '/plans',
      },
      {
        title: SITE_MENU.XtraRateMgmt,
        key: 'XtraRateMgmt',
        link: '/extra-rates',
      },
    ],
  },
  {
    title: SITE_MENU.SalesPromSec,
    icon: CouponVariant,
    key: 'SalesPromotions',
    class: 'fullPathFill',
    isDisabled: false,
    subMenu: [
      {
        title: SITE_MENU.ProvCostSlsPromo,
        key: 'ProvCostSlsPromo',
        link: '/sales-promotions',
      },
      {
        title: SITE_MENU.SpoSlsPromoMgmt,
        key: 'SpoSlsPromoMgmt',
        link: '/campaigns',
      },
    ],
  },
  {
    title: SITE_MENU.ReservSec,
    icon: BookingManagement,
    key: 'Reservations',
    class: 'innerGraphicsFill',
    isDisabled: false,
    subMenu: [
      {
        title: SITE_MENU.BookMgmtEx,
        key: 'BookMgmt',
        link: '/booking',
      },

      {
        title: SITE_MENU.RevMgmt,
        key: 'RevMgmt',
        link: '/reviews',
      },
    ],
  },
  {
    title: SITE_MENU.FinancSec,
    icon: FinanceManagement,
    class: 'financeManagementStrokes',
    key: 'FinanceManagement',
    isDisabled: false,
    subMenu: [
      {
        title: SITE_MENU.AccReport,
        key: 'AccReport',
        link: '/accountingReport',
      },
    ],
  },
  {
    title: SITE_MENU.AccessCtrlSec,
    icon: AccessManagement,
    key: 'AccessControl',
    isDisabled: false,
    class: 'allButFirstPathFill',
    subMenu: [
      {
        title: SITE_MENU.SysAcctAccCtrlMgmt,
        key: 'SysAcctAccCtrlMgmt',
        link: '/access',
      },
      {
        title: SITE_MENU_MAP.ALIAS_MANAGEMENT.label,
        key: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
        link: '/alias',
      },
    ],
  },
];

export const internalMenus = [
  {
    title: SITE_MENU.Home,
    icon: HomeVariant,
    link: '/',
    key: 'Home',
    class: 'fullPathFill',
  },
  {
    title: SITE_MENU.ProvMgmtSec,
    icon: City,
    key: 'ProviderManagement',
    class: 'innerGraphicsFill',
    subMenu: [
      {
        title: SITE_MENU.ProvMgmt,
        key: 'ProvMgmt',
        link: '/provider',
      },
      {
        title: SITE_MENU.ProvGropMgmt,
        key: 'ProvGropMgmt',
        link: '/providerGroup',
      },
      {
        title: SITE_MENU_MAP.PROVIDER_RESTRICTION_MANAGEMENT.label,
        key: SITE_MENU_MAP.PROVIDER_RESTRICTION_MANAGEMENT.authKey,
        link: '/providerRestrictions',
      },
    ],
  },
  {
    title: SITE_MENU.ReservationControl,
    icon: BookingManagement,
    class: 'innerGraphicsFill',
    key: 'ReservationControl',
    subMenu: [
      {
        title: SITE_MENU.BookMgmt,
        key: 'BookMgmt',
        link: '/booking',
      },
      {
        title: SITE_MENU.FaxMgmt,
        key: 'FaxMgmt',
        link: '/faxManagement',
      },
    ],
  },
  {
    title: SITE_MENU.ConMgmtSec,
    icon: MasterAssets,
    class: 'allButFirstPathFill',
    key: 'ConnectivityManagement',
    subMenu: [
      {
        title: SITE_MENU.ChanlMgmt,
        key: 'ChanlMgmt',
        link: '/channelManagement',
      },
      {
        title: SITE_MENU.DistrMgmt,
        key: 'DistrMgmt',
        link: '/distributorManagement',
      },
    ],
  },
  {
    title: SITE_MENU.SiteContentControl,
    icon: AssetControl,
    class: 'innerGraphicsFill',
    key: 'SiteContentControl',
    subMenu: [
      {
        title: SITE_MENU.PlcSpotMgmt,
        key: 'PlcSpotMgmt',
        link: '/places',
      },
      {
        title: SITE_MENU.VisConGrpMgmt,
        key: 'VisConGrpMgmt',
        link: '/visualContentGroup',
      },
      {
        title: SITE_MENU.ConsSFCMgmt,
        key: 'ConsSFCMgmt',
        link: '/filterComponent',
      },
      {
        title: SITE_MENU_MAP.CONSUMER_SITE_FILTER_GROUP_MANAGEMENT.label,
        key: SITE_MENU_MAP.CONSUMER_SITE_FILTER_GROUP_MANAGEMENT.authKey,
        link: '/filterGroup',
      },

      {
        title: SITE_MENU.FeatGrpMgmt,
        key: 'FeatGrpMgmt',
        link: '/featureGroup',
      },

      {
        title: SITE_MENU.FeatMgmt,
        key: 'FeatMgmt',
        link: '/feature',
      },

      {
        title: SITE_MENU.RevEx,
        key: 'RevEx',
        link: '/reviews',
      },
      {
        title: SITE_MENU_MAP.OBJECT_SCORE.label,
        key: SITE_MENU_MAP.OBJECT_SCORE.authKey,
        link: '/objectScore',
      },
      {
        title: SITE_MENU.CertificationManagement,
        key: 'CertificationManagement',
        link: '/certifications',
      },
      {
        title: SITE_MENU.LuxuryInvitation,
        key: 'LuxuryInvitation',
        link: '/luxuryInvitation',
      },
      {
        title: SITE_MENU_MAP.SEO_MANAGEMENT.label,
        key: SITE_MENU_MAP.SEO_MANAGEMENT.authKey,
        link: '/seoManagement',
      },
    ],
  },
  {
    title: SITE_MENU.LabelMgmt,
    icon: Labels,
    key: 'LabelManagement',
    class: 'fullPathFill',
    subMenu: [
      {
        title: SITE_MENU.TvlrLblMgmt,
        key: 'TvlrLblMgmt',
        link: '/travelerLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.ExtrntLblMgmt,
        key: 'ExtrntLblMgmt',
        link: '/labelManagement',
      },
      {
        title: SITE_MENU.SeoLblMgmt,
        key: 'SeoLblMgmt',
        link: '/seoLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.TvlrCommonLblMgmt,
        key: 'TvlrCommonLblMgmt',
        link: '/tvlrCommonLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.DPLblMgmt,
        key: 'DPLblMgmt',
        link: '/dpLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.MyPageLblMgmt,
        key: 'MyPageLblMgmt',
        link: '/myPageLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.RentalCarLblMgmt,
        key: 'RentalCarLblMgmt',
        link: '/rentalCarLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.TravelAppLblMgmt,
        key: 'TravelAppLblMgmt',
        link: '/travelAppLabelManagement',
        isDisabled: false,
      },
      {
        title: SITE_MENU.TranslationMgmt,
        key: 'TranslationMgmt',
        link: '/manualTranslation',
      },
      {
        title: SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.label,
        key: SITE_MENU_MAP.PROVIDER_DATA_TRANSLATION.authKey,
        link: '/providerDataTranslation',
      },
    ],
  },
  {
    title: SITE_MENU.Promotions,
    icon: CouponVariant,
    key: 'Promotions',
    class: 'fullPathFill',
    subMenu: [
      {
        title: SITE_MENU.RakutenSP,
        key: 'RakutenSP',
        link: '/salespromotion',
      },
      {
        title: SITE_MENU.SponsoredSP,
        key: 'SpoSlsPromoMgmt',
        link: '/campaigns',
      },
      {
        title: SITE_MENU.UserSegMgmt,
        key: 'UserSegMgmt',
        link: '/userSegment',
      },
    ],
  },
  {
    title: SITE_MENU.InternalUserMgmt,
    icon: AccessManagement,
    class: 'allButFirstPathFill',

    key: 'InternalUserMgmt',
    subMenu: [
      {
        title: SITE_MENU.IntUsrAccCtrlMgmt,
        key: 'IntUsrAccCtrlMgmt',
        link: '/access',
      },
      {
        title: SITE_MENU_MAP.ALIAS_MANAGEMENT.label,
        key: SITE_MENU_MAP.ALIAS_MANAGEMENT.authKey,
        link: '/alias',
      },
    ],
  },
  {
    title: SITE_MENU.Notification,
    icon: NotificationVariant,
    key: 'Notification',
    link: '/notifications',
    class: 'fullPathFill',
  },
  {
    title: SITE_MENU.Announcement,
    icon: UserReviewStar,
    key: 'Announcement',
    link: '/announcement',
    class: 'fullPathFill',
  },
];

export const providerGroupMenus = [
  {
    title: SITE_MENU.Home,
    icon: HomeVariant,
    class: 'fullPathFill',
    link: '/',
    key: 'Home',
  },
  {
    title: SITE_MENU.PgProviderMgmt,
    icon: MasterAssets,
    class: 'allButFirstPathFill',
    link: '/provider',
    key: 'ProvMgmt',
  },
  {
    title: SITE_MENU.PgBasicInfo,
    icon: EditVariant,
    class: 'fullPathFill',
    link: '/basicInfo',
    key: 'BasicInfo',
  },
  {
    title: SITE_MENU.PgReservation,
    icon: BookingManagement,
    class: 'innerGraphicsFill',
    link: '/booking',
    key: 'BookMgmt',
  },
];

export const getFooterMenu = (designations: string[]) => {
  const hasLuxury = designations.includes(DESIGNATION.LUXURY);

  return [
    ...(hasLuxury
      ? [
          {
            title: SITE_MENU.LUX_TC,
            icon: MasterAssets,
            class: 'masterAssetsIconStyle',
            key: 'LUX_TC',
            link: `${env('UNIVERSAL_LUX_TC_REDIRECT_URL')}`,
          },
        ]
      : []),
    {
      title: SITE_MENU.FAQ,
      icon: Question,
      class: 'questionIconStyle',
      key: 'FAQ',
      link: `${env('UNIVERSAL_FAQ_REDIRECT_URL')}`,
    },
    {
      title: SITE_MENU.eLearing,
      icon: ELearningComputer,
      key: 'E_Learning',
      class: 'learningIconStyle',
      link: `${env('UNIVERSAL_E_LEARNING_REDIRECT_URL')}`,
    },
  ];
};
