import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { pushLocation } from 'store/__router/actions';

import {
  ApiStatus,
  Deletable,
  DeletableDialog,
  Promotions,
  SalesPromotionDetailAPI,
  SalesPromotionId,
  SalsPromotionsErrors,
} from 'SalesPromotion-Types';
import { ApiFetchPlaceDetail } from '../place/apis';
import {
  ApiDeleteSinglePromotion,
  ApiFetchDeletablePromotion,
  ApiFetchPromotions,
  ApiFetchSinglePromotion,
  ApiPostPromotion,
  ApiPutPromotion,
} from './apis';

export const clearCurrentPromotion = createStandardAction('CLEAR_SALES_PROMOTION')();
export const clearCurrentDeletable = createStandardAction('CLEAR_DELETABLE_PROMOTION')();

export const fetchPromotionsAsync = createAsyncAction(
  'FETCH_SALES_PROMOTIONS_REQUEST',
  'FETCH_SALES_PROMOTIONS_SUCCESS',
  'FETCH_SALES_PROMOTIONS_FAILURE',
)<undefined, Promotions, SalsPromotionsErrors[]>();

export const fetchDeletablePromotionAsync = createAsyncAction(
  'FETCH_DELETABLE_PROMOTIONS_REQUEST',
  'FETCH_DELETABLE_PROMOTIONS_SUCCESS',
  'FETCH_DELETABLE_PROMOTIONS_FAILURE',
)<undefined, Deletable, SalsPromotionsErrors[]>();

export const putDeletableDialogAsync = createAsyncAction(
  'DELETABLE_DIALOG_PROMOTION_REQUEST',
  'DELETABLE_DIALOG_PROMOTION_SUCCESS',
  'DELETABLE_DIALOG_PROMOTION_FAILURE',
)<undefined, DeletableDialog, SalsPromotionsErrors[]>();

export const fetchSinglePromotionAsync = createAsyncAction(
  'FETCH_SALES_PROMOTION_REQUEST',
  'FETCH_SALES_PROMOTION_SUCCESS',
  'FETCH_SALES_PROMOTION_FAILURE',
)<SalesPromotionId, SalesPromotionDetailAPI, SalsPromotionsErrors[]>();

export const postSalesPromotionAsync = createAsyncAction(
  'POST_SALES_PROMOTION_REQUEST',
  'POST_SALES_PROMOTION_SUCCESS',
  'POST_SALES_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const putSalesPromotionAsync = createAsyncAction(
  'PUT_SALES_PROMOTION_REQUEST',
  'PUT_SALES_PROMOTION_SUCCESS',
  'PUT_SALES_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

export const deleteSalesPromotionAsync = createAsyncAction(
  'DELETE_SALES_PROMOTION_REQUEST',
  'DELETE_SALES_PROMOTION_SUCCESS',
  'DELETE_SALES_PROMOTION_FAILURE',
)<undefined, ApiStatus, ApiStatus[]>();

// TODO https://jira.rakuten-it.com/jira/browse/EVEREST-10807
export const fetchSalesPromotions = (queries: any, rejectIfFetching?: boolean): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  const isFetching = getState().salesPromotion.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchPromotionsAsync.request());
  const res = await ApiFetchPromotions(apiClient, queries);
  dispatch(fetchPromotionsAsync.success(res));
};

export const fetchSingleSalesPromotion = (
  promotionId: string,
): AppThunk<Promise<SalesPromotionDetailAPI | undefined>> => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchSinglePromotionAsync.request(promotionId));
  const promotion = await ApiFetchSinglePromotion(apiClient, promotionId);
  const state = getState();
  // On every request we store the promotion id in the store
  // The last made user request will be the one stored in the store
  // If the promotionId in this request does not match the one in the store then this is not the last made user request
  // Therefore we do not update the store with the API response since it is a stale request
  if (state.salesPromotion.promotionId === promotionId) {
    if (promotion?.placeId) {
      const places = await ApiFetchPlaceDetail(apiClient, promotion.placeId);
      dispatch(fetchSinglePromotionAsync.success({ ...promotion, ancestors: places.ancestors }));
    } else {
      dispatch(fetchSinglePromotionAsync.success({ ...promotion, ancestors: [] }));
    }
    return promotion;
  }
};

export const fetchDeletableSalesPromotion = (promotionId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  try {
    dispatch(fetchDeletablePromotionAsync.request());
    const res = await ApiFetchDeletablePromotion(apiClient, promotionId);
    dispatch(fetchDeletablePromotionAsync.success(res));
    dispatch(putDeletableDialogAsync.success({ isShow: true }));
  } catch (error) {
    error.status === 403
      ? dispatch(fetchDeletablePromotionAsync.success(error as ApiStatus)) &&
        dispatch(putDeletableDialogAsync.success({ isShow: true }))
      : dispatch(fetchGlobalErrorDialogAsync.success(error as ApiStatus));
  }
};

// TODO https://jira.rakuten-it.com/jira/browse/EVEREST-10807
export const postSalesPromotion = (data: any): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(postSalesPromotionAsync.request());
  try {
    const res = await ApiPostPromotion(apiClient, data);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(postSalesPromotionAsync.success(res));

    if (res.id) {
      dispatch(pushLocation(`/internal/salespromotion/${res.id}`));
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(postSalesPromotionAsync.failure(err));
  }
};

export const putSalesPromotion = (
  data: any,
  promotionId: string,
  state?: { prevSearch: string },
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(putSalesPromotionAsync.request());
  try {
    const res = await ApiPutPromotion(apiClient, data, promotionId);
    dispatch(fetchGlobalErrorDialogAsync.success(res));
    dispatch(putSalesPromotionAsync.success(res));
    if (res.status === 200) {
      dispatch(pushLocation({ pathname: `/internal/salespromotion/${promotionId}`, state }));
    }
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success(err));
    dispatch(putSalesPromotionAsync.failure(err));
  }
};

export const deleteSalesPromotion = (
  promotionId: string,
  promotionName: string,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(deleteSalesPromotionAsync.request());
  try {
    const res = await ApiDeleteSinglePromotion(apiClient, promotionId);

    // Pass the deleted object name
    res.message = promotionName;

    dispatch(fetchGlobalErrorDialogAsync.success({ ...res, isDelete: true }));
    dispatch(deleteSalesPromotionAsync.success(res));
  } catch (err) {
    dispatch(fetchGlobalErrorDialogAsync.success({ ...err, isDelete: true }));
    dispatch(deleteSalesPromotionAsync.failure(err));
  }
};
