import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  defaultDeletableDialog,
  defaultPromotionDeletable,
  defaultPromotionDetail,
} from './initialValues';

import { deleteCampaignAsync } from 'store/campaign/actions';

import { APPLICABLE_SETTINGS_OPTIONS } from 'constants/promotion';
import {
  CouponCode,
  Promotions as PromotionsType,
  SalesPromotionDetail,
  SalesPromotionId,
  SalsPromotionsErrors,
} from 'SalesPromotion-Types';
import {
  clearCurrentDeletable,
  clearCurrentPromotion,
  fetchDeletablePromotionAsync,
  fetchPromotionsAsync,
  fetchSinglePromotionAsync,
  postSalesPromotionAsync,
  putDeletableDialogAsync,
  putSalesPromotionAsync,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPromotionsAsync.request], (state, action) => true)
  .handleAction(
    [fetchPromotionsAsync.success, fetchPromotionsAsync.failure],
    (state, action) => false,
  );

export const isFetchingPromotionDetails = createReducer(false as boolean)
  .handleAction([fetchSinglePromotionAsync.request], (state, action) => true)
  .handleAction(
    [fetchSinglePromotionAsync.success, fetchSinglePromotionAsync.failure],
    (state, action) => false,
  );

export const isCreating = createReducer(false)
  .handleAction([postSalesPromotionAsync.request, putSalesPromotionAsync.request], () => true)
  .handleAction(
    [
      postSalesPromotionAsync.success,
      postSalesPromotionAsync.failure,
      putSalesPromotionAsync.success,
      putSalesPromotionAsync.failure,
    ],
    () => false,
  );

export const isDeleting = createReducer(false)
  .handleAction(deleteCampaignAsync.request, () => true)
  .handleAction([deleteCampaignAsync.success, deleteCampaignAsync.failure], () => false);

export const promotions = createReducer({} as PromotionsType).handleAction(
  fetchPromotionsAsync.success,
  (state, action) => {
    const shouldConcat = action.payload.offset > state.offset;

    return {
      ...action.payload,
      contents:
        state.contents && shouldConcat
          ? state.contents.concat(action.payload.contents)
          : action.payload.contents,
    };
  },
);

export const promotion = createReducer((defaultPromotionDetail as unknown) as SalesPromotionDetail)
  .handleAction(fetchSinglePromotionAsync.success, (state, action) => {
    const { payload } = action;
    const couponCodes: CouponCode[] = [];
    payload.couponCodes.forEach((coupon: CouponCode) => {
      couponCodes.push(coupon);
    });
    const updatedPayload = {
      ...payload,
      effectivePeriod: {
        startDate: payload.effectivePeriod.startDateTime.split('T')[0],
        startTime: payload.effectivePeriod.startDateTime.split('T')[1],
        endDate: payload.effectivePeriod.endDateTime.split('T')[0],
        endTime: payload.effectivePeriod.endDateTime.split('T')[1],
      },
      incentive: {
        incentiveType: payload.incentiveType,
        discount: payload.discount
          ? payload.discount
          : {
              discountValueType: payload.point.pointValueType,
              discountValue: payload.point.pointValue,
              discountPeak: payload.point.pointPeak,
            },
      },
      offer: {
        offerType: payload.offerType,
        offer: couponCodes,
      },
      searchCondition: {
        ...payload.searchCondition,
        guestCapacity: payload.searchCondition.guestsNumber,
      },
    };

    return updatedPayload;
  })
  .handleAction(
    clearCurrentPromotion,
    (state, action) => (defaultPromotionDetail as unknown) as SalesPromotionDetail,
  );

export const deletable = createReducer(defaultPromotionDeletable)
  .handleAction(fetchDeletablePromotionAsync.success, (state, action) => {
    const { payload } = action;
    const updatedPayload = {
      ...payload,
      statusCode: payload.status,
    };
    return updatedPayload;
  })
  .handleAction(clearCurrentDeletable, (state, action) => defaultPromotionDeletable);

export const deletableDialog = createReducer(defaultDeletableDialog).handleAction(
  putDeletableDialogAsync.success,
  (state, action) => action.payload,
);

export const errors = createReducer([] as SalsPromotionsErrors[]).handleAction(
  fetchPromotionsAsync.failure,
  (state, action) => action.payload,
);

export const promotionId = createReducer<SalesPromotionId>('').handleAction(
  fetchSinglePromotionAsync.request,
  (state, action) => action.payload,
);

const salesPromotionsReducer = combineReducers({
  isFetching,
  isFetchingPromotionDetails,
  promotions,
  promotion,
  deletable,
  deletableDialog,
  errors,
  isCreating,
  isDeleting,
  promotionId,
});

export default salesPromotionsReducer;
export type SalesPromotionsState = ReturnType<typeof salesPromotionsReducer>;
