import React, { ReactNode } from 'react';

import cx from '../../utils/classnames';

import styles from './balloon.module.scss';

export type ArrowDirection =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type Props = {
  className?: string;
  triangleClassName?: string;
  children?: ReactNode;

  /** arrow direction */
  direction?: ArrowDirection;

  /** callback function when balloon is clicked */
  onClick?: () => void;

  /** flag to define content min-height */
  hasContentMinHeight?: boolean;
};

export default function Balloon(props: Props) {
  const {
    className,
    triangleClassName,
    direction = '',
    hasContentMinHeight = true,
    ...rest
  } = props;
  const isDirection = !!direction;

  return (
    <div
      className={cx(
        className,
        styles.balloon,
        isDirection && styles[direction],
        hasContentMinHeight ? styles.ballMinH : '',
      )}
      data-testid="balloon-wrapper"
      {...rest}
    >
      <div
        className={cx(styles.text, hasContentMinHeight ? styles.textMinH : '')}
        data-testid="balloon-text"
      >
        {props.children}
      </div>
      {isDirection && (
        <div className={cx(styles.triangle, triangleClassName)} data-testid="balloon-triangle" />
      )}
    </div>
  );
}
