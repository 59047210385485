import React, { ReactNode } from 'react';
import { Attention, Correct, Info } from '@travel/icons/ui';
import { FlatButton, Dialog } from '../..';

import cx from '../../utils/classnames';

import styles from './popupMessage.module.scss';

type DeleteDialogLabel = {
  title: ReactNode;
  subTitle: ReactNode;
  message: ReactNode;
};

type PopUpLabel = {
  errorDialogTitle?: ReactNode;
  errorTitle?: ReactNode;
  successTitle?: ReactNode;
  successSubTitle?: ReactNode;
};

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Custom style for message */
  customMessageClassName?: string;
  /** Button text to be displayed */
  buttonText?: ReactNode;
  /** Custom Title for Dialog */
  customDialogTitle?: ReactNode;
  /** Flagging to display either success or fail */
  isSuccess?: boolean;
  /** Flagging to display confirm */
  isConfirm?: boolean;
  /** Flagging to determine if deleting data */
  isDelete?: boolean;
  /** Flagging to open dialog */
  isOpen?: boolean;
  /** Error message to show on failure */
  errorMessage?: ReactNode;
  /** Custom Title of pop-up */
  customTitle?: ReactNode;
  /** Custom Sub-Title of pop-up */
  customSubTitle?: ReactNode;
  /** Hide heading for selected pages */
  shouldHideHeading?: boolean;
  /** Delete Dialog label */
  deleteDialogLabel?: DeleteDialogLabel;
  /** Pop Up Title and Subtitle Labels */
  popUpLabel?: PopUpLabel;
  /** Callback on close dialog */
  onCloseDialog?: () => void;
  /** Flag if this dialog will overlapped another dialog **/
  isDisplayedOnDialog?: boolean;
};

function PopupMessage(props: Props) {
  const {
    className,
    customMessageClassName = '',
    buttonText = 'OK',
    isSuccess = true,
    isConfirm = false,
    isDelete,
    isOpen,
    errorMessage,
    customTitle,
    customSubTitle,
    customDialogTitle,
    shouldHideHeading,
    deleteDialogLabel = {
      title: 'Deleted Successfully',
      subTitle: 'Deleted Successfully!',
      message: '',
    },
    popUpLabel = {
      errorDialogTitle: 'Error message',
      errorTitle: 'Something went wrong',
      successTitle: 'Successfully Saved!',
      successSubTitle: 'Form has been successfully saved',
    },
    onCloseDialog,
    isDisplayedOnDialog,
  } = props;

  const showSubtitle = () => {
    let subTitle: React.ReactNode = '';

    if (!!customSubTitle) {
      subTitle = customSubTitle;
    } else if (isSuccess) {
      if (isDelete) {
        subTitle = deleteDialogLabel?.message || '';
      } else {
        subTitle = popUpLabel?.successSubTitle;
      }
    } else {
      subTitle = errorMessage;
    }

    return subTitle;
  };

  return (
    <Dialog
      className={styles.dialog}
      contentClassName={styles.contentDialog}
      headerClassName={styles.headerDialog}
      title={
        customDialogTitle
          ? customDialogTitle
          : isSuccess && isDelete
          ? deleteDialogLabel?.title
          : !isSuccess && popUpLabel?.errorDialogTitle
      }
      isOpen={isOpen}
      onClose={onCloseDialog}
      isDisplayedOnDialog={isDisplayedOnDialog}
    >
      <div data-testid="popupMessage-wrapper" className={cx(className, styles.wrapper)}>
        <div className={styles.titleContainer}>
          {isSuccess ? (
            isConfirm ? (
              <Info data-testid="popupMessage-wrapper-infoIcon" size={24} />
            ) : (
              <Correct data-testid="popupMessage-wrapper-correctIcon" size={24} />
            )
          ) : (
            <Attention data-testid="popupMessage-wrapper-attentionIcon" size={24} />
          )}
          {!shouldHideHeading && (
            <span className={styles.title} data-testid="popupMessage-title-text">
              {!!customTitle
                ? customTitle
                : isSuccess
                ? isDelete
                  ? deleteDialogLabel?.subTitle || ''
                  : popUpLabel?.successTitle
                : popUpLabel?.errorTitle}
            </span>
          )}
        </div>
        <section
          data-testid="popupMessage-subtitle"
          className={cx(styles.message, customMessageClassName)}
        >
          {showSubtitle()}
        </section>
        <FlatButton
          className={styles.okButton}
          classType="primary"
          onClick={onCloseDialog}
          type="button"
          data-testid="popupMessage-ok-button"
        >
          {buttonText}
        </FlatButton>
      </div>
    </Dialog>
  );
}

export default PopupMessage;
