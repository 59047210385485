import { ApiClient } from '@travel/api-client';

import { ApiStatus, CancelPolicy, CancelPolicyContent } from 'CancelPolicy-Types';
import providerPrefix from 'constants/api-prefix';

export const ApiFetchCancelPolicyList = (
  http: ApiClient,
  providerId: string,
  params: Object,
): Promise<CancelPolicy> => {
  return http.get(`${providerPrefix}/${providerId}/cancelPolicies`, { values: params });
};

export const ApiFetchCancelPolicy = (
  http: ApiClient,
  providerId: string,
  policyId: string,
  params: Object,
): Promise<CancelPolicyContent> => {
  return http.get(`${providerPrefix}/${providerId}/cancelPolicies/${policyId}`, {
    values: params,
  });
};

export const fetchDeletableCancellation = (
  http: ApiClient,

  providerId: string,
  cancelPolicyId: string,
): Promise<ApiStatus> =>
  http
    .get(`${providerPrefix}/${providerId}/cancelPolicies/${cancelPolicyId}/deletable`, {
      ignoreCommonErrorHandler: true,
    })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);

export const create = (
  http: ApiClient,
  providerId: string,
  data: CancelPolicyContent,
): Promise<ApiStatus> => {
  return http
    .post(`${providerPrefix}/${providerId}/cancelPolicies`, { values: data })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const update = (
  http: ApiClient,
  providerId: string,
  data: CancelPolicyContent,
): Promise<ApiStatus> => {
  const { id: cancelPolicyId, ...putData } = data;
  return http
    .put(`${providerPrefix}/${providerId}/cancelPolicies/${cancelPolicyId}`, { values: putData })
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};

export const deleteCancellationPolicy = (
  http: ApiClient,
  providerId: string,
  cancelPolicyId: string,
): Promise<ApiStatus> => {
  return http
    .delete(`${providerPrefix}/${providerId}/cancelPolicies/${cancelPolicyId}`, {})
    .then((data: ApiStatus) => data)
    .catch((data: ApiStatus) => data);
};
