import { Response } from 'express';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { MiddlewareType } from 'utils/middlewareHelpers';
import { ApiClient } from '@travel/api-client';

import { composeEnhancers } from './utils';
import rootReducer from './rootReducer';
import { History } from 'history';

const configureStore = (
  preloadedState = {},
  history?: History,
  res?: Response,
  apiClient?: ApiClient,
) => {
  const middlewares = [thunk.withExtraArgument({ history, res, apiClient }) as MiddlewareType];
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  return store;
};

export default configureStore;
