import { createAsyncAction, AppThunk } from 'typesafe-actions';

import { fetchGlobalErrorDialogAsync } from 'store/globalErrorDialog/actions';

import { FaxManagementErrors, FaxManagementItems, FaxQueryParams } from 'FaxManagement-Types';
import { fetch, ApiDownloadFax, ResendFax } from './apis';

interface Navigator {
  msSaveOrOpenBlob?: (blob: Blob) => void;
}
export const fetchFaxManagementAsync = createAsyncAction(
  'FETCH_FAX_MANAGEMENT_REQUEST',
  'FETCH_FAX_MANAGEMENT_SUCCESS',
  'FETCH_FAX_MANAGEMENT_FAILURE',
)<undefined, FaxManagementItems[], FaxManagementErrors[]>();

export const downloadFileAsync = createAsyncAction(
  'DOWNLOAL_FILE_REQUEST',
  'DOWNLOAL_FILE_SUCCESS',
  'DOWNLOAL_FILE_FAILURE',
)<undefined, Object, FaxManagementErrors[]>();

export const resendFaxAsync = createAsyncAction(
  'RESEND_FAX_REQUEST',
  'RESEND_FAX_SUCCESS',
  'RESEND_FAX_FAILURE',
)<undefined, string, FaxManagementErrors[]>();

export const fetchFaxManagement = (
  params: FaxQueryParams,
  rejectIfFetching?: boolean,
): AppThunk => async (dispatch, getState, { apiClient }) => {
  const isFetching = getState().faxManagement.isFetching;
  if (isFetching && rejectIfFetching) return;

  dispatch(fetchFaxManagementAsync.request());
  try {
    const response = await fetch(apiClient, params);
    dispatch(fetchFaxManagementAsync.success(response));
  } catch (error) {
    dispatch(fetchFaxManagementAsync.failure(error));
  }
};

export const downloadFile = (faxId: string): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(downloadFileAsync.request());
  const res = await ApiDownloadFax(apiClient, faxId);
  try {
    const blob = await res.blob();
    const link = document.createElement('a');
    const blobObject = new Blob([blob], { type: 'text/html;charset=UTF-8' });
    const url = window.URL.createObjectURL(blobObject);

    if ((window.navigator as Navigator)?.msSaveOrOpenBlob) {
      (window.navigator as Navigator).msSaveOrOpenBlob!(blobObject);
    } else {
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
    document.body.removeChild(link);
  } catch (error) {
    dispatch(downloadFileAsync.failure(error));
  }
  dispatch(downloadFileAsync.success(res));
};

export const resendFax = (faxId: string): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(resendFaxAsync.request());
  try {
    const res = await ResendFax(apiClient, faxId);
    dispatch(resendFaxAsync.success(res));
    dispatch(fetchGlobalErrorDialogAsync.success({ status: 200, message: '' }));
  } catch (error) {
    dispatch(resendFaxAsync.failure(error));
  }
};
