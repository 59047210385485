import React from 'react';

import {
  CovidBath,
  CovidCheckinAndOut,
  CovidEmergency,
  CovidMeal,
  CovidRequestGuest,
  CovidRoom,
  CovidStaff,
  CovidSterilization,
  CovidVentilation,
} from '@travel/icons/service';
import { Translate } from '@travel/translation';

import { SizeCountType, SIZE_COUNT_TYPE } from 'constants/sizeTypes';

export enum OBJECT_TYPE {
  PROVIDER = 'PROVIDER',
  ITEM = 'ITEM',
  PLAN = 'RATE_PLAN',
  SALES_PROMOTION = 'SALES_PROMOTION',
  MEDIA = 'VISUAL_CONTENT',
  FEATURE = 'FEATURE',
  PLACE = 'PLACE',
  CERTIFICATION = 'CERTIFICATION',
  EXTRA_AGE_RATE = 'EXTRA_RATE',
  CANCELLATION_POLICY = 'CANCELLATION_POLICY',
  ANY = 'ANY',
  GUEST = 'GUEST_REVIEW',
}

export const MANAGEMENT = {
  MEDIA: {
    APPEARANCE: { id: 'appearance', name: 'Appearance' },
    PEOPLE: { id: 'people', name: 'People' },
    COMPLIMENTARY: { id: 'complimentary', name: 'Complimentary' },
    EVENT: { id: 'event', name: 'Event' },
    CONTENT_VIDEO: { id: 'content_video', name: 'Content_Video' },
    GENERAL: { id: 'general', name: 'General' },
  },
  EXTRA: {
    BENEFIT: { id: 'benefit', name: 'Benefit' },
  },
  ITEM: {
    FACILITY: { id: 'facility', name: 'Facility' },
    AMENITY: { id: 'amenity', name: 'Amenity' },
    BED_TYPE: { id: 'bedType', name: 'Bed_Type' },
    ROOM_TYPE: { id: 'roomType', name: 'Room_Type' },
    VIEW: { id: 'roomView', name: 'View' },
    SERVICE: { id: 'service', name: 'Service' },
    OPEN_AIR_BATH: { id: 'openAirBath', name: 'Open_Air_Bath' },
    GRADE: { id: 'grade', name: 'Grade' },
    SMOKING: { id: 'smoking', name: 'Smoking_Type' },
    LOCATION: { id: 'location', name: 'Location' },
  },
  RATE_PLAN: {
    BENEFIT: { id: 'benefits', name: 'Benefit' },
    RESTRICTION: { id: 'restriction', name: 'Restriction' },
    MEAL: { id: 'meal', name: 'Meal' },
    OBJECTIVE: { id: 'objective', name: 'Objective' },
    MEAL_PLACE: { id: 'mealPlace', name: 'Meal_Place' },
  },
  PROVIDER: {
    ACCOMMODATION_TYPE: { id: 'accommodationType', name: 'Accommodation_Type' },
    FACILITY: { id: 'facility', name: 'Facility' },
    OBJECTIVE: { id: 'objective', name: 'Objective' },
    CATEGORY: { id: 'category', name: 'Category' },
    SERVICE: { id: 'service', name: 'Service' },
    ACCESSIBILITY: { id: 'accessibility', name: 'Accessibility' },
    SUPPORTED_LANGUAGES: { id: 'supportedLanguages', name: 'Supported_Language' },
    SUPPORTED_PAYMENT: { id: 'supportedPayment', name: 'Supported_Payment' },
    CATEGORY_BY_MARKETING: { id: 'marketingCategory', name: 'Category_By_Marketing' },
    COVID19_STAFF: { id: 'covid19', name: 'COVID19_Staff' },
    COVID19_STERILIZATION: { id: 'covid19', name: 'COVID19_Sterilization' },
    COVID19_VENTILATION: { id: 'covid19', name: 'COVID19_Ventilation' },
    COVID19_CHECK_IN_AND_OUT: { id: 'covid19', name: 'COVID19_Check_In_and_Out' },
    COVID19_ROOM: { id: 'covid19', name: 'COVID19_Room' },
    COVID19_MEAL: { id: 'covid19', name: 'COVID19_Meal' },
    COVID19_BATHTUB: { id: 'covid19', name: 'COVID19_Bathtub' },
    COVID19_REQUEST_TO_GUEST: { id: 'covid19', name: 'COVID19_Request_to_Guest' },
    COVID19_EMERGENCY: { id: 'covid19', name: 'COVID19_Emergency' },
    COVID19_REMARKS: { id: 'covid19', name: 'COVID19_Remarks' },
    TRANSPORTATION_MODE: { id: 'transportationMode', name: 'Transportation_Method' },
  },
  SALES_PROMOTION: {
    CHARACTERISTIC: { id: 'characteristic', name: 'Characteristic' },
    CAMPAIGN: { id: 'campaign', name: 'Campaign' },
  },
} as const;

export const MEDIA_MANAGEMENT = [
  MANAGEMENT.MEDIA.APPEARANCE.name,
  MANAGEMENT.MEDIA.PEOPLE.name,
  MANAGEMENT.MEDIA.COMPLIMENTARY.name,
  MANAGEMENT.MEDIA.EVENT.name,
  MANAGEMENT.MEDIA.CONTENT_VIDEO.name,
  MANAGEMENT.MEDIA.GENERAL.name,
];

export const COVID19_MANGEMENTS = [
  MANAGEMENT.PROVIDER.COVID19_STAFF.name,
  MANAGEMENT.PROVIDER.COVID19_STERILIZATION.name,
  MANAGEMENT.PROVIDER.COVID19_VENTILATION.name,
  MANAGEMENT.PROVIDER.COVID19_CHECK_IN_AND_OUT.name,
  MANAGEMENT.PROVIDER.COVID19_ROOM.name,
  MANAGEMENT.PROVIDER.COVID19_MEAL.name,
  MANAGEMENT.PROVIDER.COVID19_BATHTUB.name,
  MANAGEMENT.PROVIDER.COVID19_REQUEST_TO_GUEST.name,
  MANAGEMENT.PROVIDER.COVID19_EMERGENCY.name,
  MANAGEMENT.PROVIDER.COVID19_REMARKS.name,
];

export const COVID19_REMARKS_MANAGEMENT = MANAGEMENT.PROVIDER.COVID19_REMARKS.name;

export const FEATURE_GROUP_ICONS: { [key: string]: React.ReactElement | null } = {
  [MANAGEMENT.PROVIDER.COVID19_STAFF.name]: <CovidStaff />,
  [MANAGEMENT.PROVIDER.COVID19_STERILIZATION.name]: <CovidSterilization />,
  [MANAGEMENT.PROVIDER.COVID19_VENTILATION.name]: <CovidVentilation />,
  [MANAGEMENT.PROVIDER.COVID19_CHECK_IN_AND_OUT.name]: <CovidCheckinAndOut />,
  [MANAGEMENT.PROVIDER.COVID19_ROOM.name]: <CovidRoom />,
  [MANAGEMENT.PROVIDER.COVID19_MEAL.name]: <CovidMeal />,
  [MANAGEMENT.PROVIDER.COVID19_BATHTUB.name]: <CovidBath />,
  [MANAGEMENT.PROVIDER.COVID19_REQUEST_TO_GUEST.name]: <CovidRequestGuest />,
  [MANAGEMENT.PROVIDER.COVID19_EMERGENCY.name]: <CovidEmergency />,
  [MANAGEMENT.PROVIDER.COVID19_REMARKS.name]: null,
};

export const initialSortValue = {
  options: [
    {
      text: <Translate id="Supplier_Enumeration.Sort.Feature_Score.High_Low" />,
      value: 'score=desc',
    },
    {
      text: <Translate id="Supplier_Enumeration.Sort.Feature_Score.Low_High" />,
      value: 'score=asc',
    },
  ],
  selectedValue: 'score=desc',
};

/** the list of management names and sizeTypes */
const featureSizeCountList: { [key: string]: SizeCountType } = {
  [MANAGEMENT.ITEM.BED_TYPE.name]: SIZE_COUNT_TYPE.COUNT,
};
export const getSizeCountType = (management: string): SizeCountType => {
  // if the management name has sizeCountType(COUNT|SIZE_TYPES|BOTH), return it.
  return featureSizeCountList[management] || SIZE_COUNT_TYPE.NONE;
};

/** the list of management names which has feature description */
// provider
const featureDescriptionListForProvider: string[] = [
  MANAGEMENT.PROVIDER.FACILITY.name,
  MANAGEMENT.PROVIDER.OBJECTIVE.name,
  MANAGEMENT.PROVIDER.CATEGORY.name,
  MANAGEMENT.PROVIDER.SERVICE.name,
  MANAGEMENT.PROVIDER.ACCESSIBILITY.name,
  MANAGEMENT.PROVIDER.SUPPORTED_LANGUAGES.name,
  MANAGEMENT.PROVIDER.SUPPORTED_PAYMENT.name,
  MANAGEMENT.PROVIDER.COVID19_REMARKS.name,
];

/** Return each feature selection has description or not.
 * NOTE: To keep maintainability, use this function instead of integrating the flag to MANGEMENT Object.
 * The logic might depend on objectType. */
export const hasDescription = (objectType: string, management: string) => {
  switch (objectType) {
    case OBJECT_TYPE.PROVIDER:
      return featureDescriptionListForProvider.includes(management);
    // case OBJECT_TYPE.SALES_PROMOTION // add here SP logic when PDM update.
    default:
      return true;
  }
};

export const filterIdList = ['status', 'tangible'];

export const RAKUTEN_EXCLUSIVE_MANAGEMENT_NAME = 'rakuten_only';
