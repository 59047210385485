import React, { ReactNode } from 'react';

import { Translate } from '@travel/translation';

export type ENUMS<T> = {
  [key: string]: T;
};

export enum BOOKING_STATUS {
  BOOKED = 'BOOKED',
  CANCELED = 'CANCELED',
  REQUESTING = 'REQUESTING',
}

export enum AUTH_KEY {
  DPBookMgmt = 'DPBookMgmt',
  BookMgmt = 'BookMgmt',
}

export enum ModType {
  PRICE,
  DATE,
}

export enum TAX_TYPE {
  HOTEL = 'CITY_TAX',
  HOT_SPRING = 'HOT_SPRING_TAX',
}

export enum DIALOG_TYPES {
  SELECT = 'SELECT',
  PRICE_MODIFICATION = 'PRICE_MODIFICATION',
  DATE_MODIFICATION = 'DATE_MODIFICATION',
  CANCEL_CHARGE_MODIFICATION = 'CANCEL_CHARGE_MODIFICATION',
}

export enum SERVICE_TYPE {
  DP_YADO = 'DP_YADO',
  THIRD_PARTY = 'THIRD_PARTY',
  RAKUTEN = 'RAKUTEN',
}

export const DATE_FORMAT = 'MM/DD/YYYY (ddd) LT';
export const DATETIME_FORMAT = 'YYYY-MM-DD(ddd) HH:MM:SS';
export const DATE_ONLY_WITH_SPACE = 'MM/DD/YYYY (ddd)';

export const RESERVATION_STATUS_COLOR: ENUMS<'yellow' | 'scarlet' | 'lincolnGreen'> = {
  BOOKED: 'lincolnGreen',
  CANCELED: 'scarlet',
  REQUESTING: 'yellow',
};

export const OFFER_TYPES_UI: ENUMS<ReactNode> = {
  DIRECT_OFFER: <Translate id="Supplier_Enumeration.Offer_Type.Direct" />,
  COUPON_OFFER: <Translate id="Supplier_Enumeration.Offer_Type.Coupon" />,
};

export const OFFER_TYPES: ENUMS<ReactNode> = {
  COUPON_WITH_CODE: <Translate id="Supplier_Enumeration.Coupon_Type.With_Code" />,
  COUPON_WITHOUT_CODE: <Translate id="Supplier_Enumeration.Coupon_Type.Without_Code" />,
  DIRECT: <Translate id="Supplier_Enumeration.Offer_Type.Direct" />,
};

export const PAYMENT_METHODS: ENUMS<string> = {
  ON_SITE: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Type.Pay_On_Site',
  AGENT_PAYMENT: 'Supplier_Enumeration.Rate_Plan.Payment_Method.Type.Agent_Payment',
};

export const RATE_UNIT: ENUMS<ReactNode> = {
  PER_ITEM: <Translate id="Supplier_Enumeration.Unit_of_Rate.Per_Item" />,
  PER_PERSON: <Translate id="Supplier_Enumeration.Unit_of_Rate.Per_Person" />,
};

export enum STEPS {
  ONE,
  TWO,
  THREE,
}

export enum RATE_UNITS {
  PER_ITEM = 'PER_ITEM',
  PER_PERSON = 'PER_PERSON',
}

export enum IncentiveType {
  DISCOUNT = 'DISCOUNT',
  POINT = 'POINT',
}

export enum ValueType {
  PERCENT = 'PERCENT',
  EXACT_AMOUNT = 'EXACT_AMOUNT',
}

export enum CANCEL_TYPES {
  CANCELLED = 'CANCELLED',
  NO_SHOW = 'NO_SHOW',
}

export enum PAYMENT_TYPES {
  ON_SITE = 'ON_SITE',
  AGENT_PAYMENT = 'AGENT_PAYMENT',
}

export enum USAGE_PRICE_TYPES {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  ITEM = 'ITEM',
}

export enum PENALTY_TYPES {
  CANCELLATION_PENALTY = 'CANCELLATION_PENALTY',
  NO_SHOW_PENALTY = 'NO_SHOW_PENALTY',
}

export enum PROMOTION_OFFER_TYPES {
  DIRECT_OFFER = 'DIRECT_OFFER',
  COUPON_OFFER = 'COUPON_OFFER',
}

export enum PROMOTION_CLASS {
  MAIN = 'MAIN',
  SUB = 'SUB',
  GOTO = 'GOTO',
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'CREDIT_CARD',
  CORPORATE_CHARGE = 'CORPORATE_CHARGE',
  LOCAL = 'LOCAL',
}

export enum PAYMENT_INFO_DETAIL_TYPE {
  CANCEL = 'CANCEL',
  REFUND = 'REFUND',
}

export const HISTORY_OFFER_TYPES_LABELS = {
  DIRECT_OFFER:
    'booking_management_fundamental.popup_provider_promotion_details.form.offer_type_Direct',
  COUPON_OFFER:
    'booking_management_fundamental.popup_provider_promotion_details.form.offer_type_Coupon',
};

export const HISTORY_MEMBER_LABELS = {
  MEMBER: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.by_whom_traveler" />
  ),
  NON_MEMBER: 'Guest',
  INTERNAL: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.by_whom_internal" />
  ),
  PROVIDER: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.by_whom_provider" />
  ),
  DISTRIBUTOR: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.by_whom_distributor" />
  ),
};

export const CANCELLATION_REASONS = {
  WANTS_TO_SHORTEN_THE_RESERVATION: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_1" />
  ),
  WANTS_TO_CHANGE_THE_RESERVATION_DATE: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_2" />
  ),
  WANTS_TO_CHANGE_THE_GUEST_INFORMATION: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_3" />
  ),
  BOOKED_ANOTHER_HOTEL_ON_DIFFERENT_SITE: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_4" />
  ),
  BOOKED_ANOTHER_HOTEL_ON_RAKUTEN_TRAVEL: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_5" />
  ),
  BOOKING_NO_LONGER_NEED: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_6" />
  ),
  OTHER: (
    <Translate id="booking_management_fundamental.Popup_Reservation_History_Details.Cancellation_Reason.Reason_7" />
  ),
};

export const HISTORY_NOTIFICATION_LABELS = {
  MAIL: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.notification_method_email" />
  ),
  FAX: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.notification_method_fax" />
  ),
};

export const PENALTY_SOURCE_LABEL = {
  RAKUTEN_POINT: <Translate id="Supplier_Enumeration.Payment_Method.Point" />,
  PREPAID_CREDIT_CARD: <Translate id="Supplier_Enumeration.Payment_Method.Credit_Card" />,
};

export const PAYMENT_METHOD_LABEL: ENUMS<ReactNode> = {
  CREDIT_CARD: <Translate id="Supplier_Enumeration.Payment_Method.Credit_Card" />,
  POINT: <Translate id="Supplier_Enumeration.Payment_Method.Point" />,
  LOCAL: (
    <Translate id="booking_management_fundamental.booking_detail.form.payment_method_on_site_payment_amount" />
  ),
  COUPON: <Translate id="Supplier_Enumeration.Payment_Method.Coupon" />,
  LOCAL_WO_AUTH: <Translate id="Supplier_Enumeration.Payment_Method.Local_WO_Auth" />,
  CORPORATE_CHARGE: <Translate id="Supplier_Enumeration.Payment_Method.Corporate_Charge" />,
  PROVIDER: <Translate id="Supplier_Enumeration.Payment_Method.Provider" />,
  RAKUTEN: <Translate id="Supplier_Enumeration.Payment_Method.Rakuten" />,
  ONE_TIME: <Translate id="Supplier_Enumeration.Rate_Plan.Payment_Method.Type.One_Time_Payment" />,
  PACKAGE: <Translate id="Supplier_Enumeration.Rate_Plan.Payment_Method.Type.Package" />,
};

export const CANCELED_PAYMENT_METHOD_LABEL: ENUMS<ReactNode> = {
  CREDIT_CARD: <Translate id="Supplier_Enumeration.Payment_Method.Credit_Card" />,
  POINT: <Translate id="Supplier_Enumeration.Payment_Method.Point" />,
  LOCAL: <Translate id="Supplier_Enumeration.Payment_Method.Local" />,
  COUPON: <Translate id="Supplier_Enumeration.Payment_Method.Coupon" />,
  LOCAL_WO_AUTH: <Translate id="Supplier_Enumeration.Payment_Method.Local_WO_Auth" />,
  CORPORATE_CHARGE: <Translate id="Supplier_Enumeration.Payment_Method.Corporate_Charge" />,
  PROVIDER: <Translate id="Supplier_Enumeration.Payment_Method.Provider" />,
  RAKUTEN: <Translate id="Supplier_Enumeration.Payment_Method.Rakuten" />,
  ONE_TIME: <Translate id="Supplier_Enumeration.Rate_Plan.Payment_Method.Type.One_Time_Payment" />,
  PACKAGE: <Translate id="Supplier_Enumeration.Rate_Plan.Payment_Method.Type.Package" />,
};

export const BOOKING_LIST_PAYMENT_METHOD_LABEL: ENUMS<ReactNode> = {
  CREDIT_CARD: (
    <Translate id="booking_management_fundamental.list.list_column5.online_card_payment" />
  ),
  POINT: <Translate id="Supplier_Enumeration.Payment_Method.Point" />,
  LOCAL: <Translate id="booking_management_fundamental.list.list_column5.on_site_payment" />,
  COUPON: <Translate id="Supplier_Enumeration.Payment_Method.Coupon" />,
  LOCAL_WO_AUTH: <Translate id="Supplier_Enumeration.Payment_Method.Local_WO_Auth" />,
  CORPORATE_CHARGE: <Translate id="Supplier_Enumeration.Payment_Method.Corporate_Charge" />,
  PROVIDER: <Translate id="Supplier_Enumeration.Payment_Method.Provider" />,
  RAKUTEN: <Translate id="Supplier_Enumeration.Payment_Method.Rakuten" />,
  ONE_TIME: <Translate id="booking_management_fundamental.list.list_column5.one_time_payment" />,
  PACKAGE: <Translate id="booking_management_fundamental.list.list_column5.package_payment" />,
};

export const PAYMENT_TYPE_LABEL: ENUMS<ReactNode> = {
  PREPAID: (
    <Translate id="booking_management_fundamental.booking_detail.form.prepaid_payment_processed_by_provider" />
  ),
  AGENT_PAYMENT: (
    <Translate id="booking_management_fundamental.booking_detail.form.online_credit_card_payment" />
  ),
  CORPORATE_CHARGE: (
    <Translate id="booking_management_fundamental.booking_detail.form.corporate_payment" />
  ),
  ON_SITE: <Translate id="booking_management_fundamental.booking_detail.form.on_site_payment" />,
};

export const PAYMENT_METHODS_ENUM = {
  CREDIT_CARD: 'CREDIT_CARD',
  POINT: 'POINT',
  LOCAL: 'LOCAL',
  COUPON: 'COUPON',
  LOCAL_WO_AUTH: 'LOCAL_WO_AUTH',
  CORPORATE_CHARGE: 'CORPORATE_CHARGE',
  ONE_TIME: 'ONE_TIME',
  PACKAGE: 'PACKAGE',
};

export const COST_OWNER_ENUM = {
  RAKUTEN: 'RAKUTEN',
  PROVIDER: 'PROVIDER',
};

export const CANCELLATION_PAYMENT_METHOD_LABEL: ENUMS<ReactNode> = {
  CREDIT_CARD: <Translate id="Supplier_Enumeration.Payment_Method.Credit_Card" />,
  POINT: <Translate id="Supplier_Enumeration.Payment_Method.Point" />,
  LOCAL: <Translate id="Supplier_Enumeration.Payment_Method.Local" />,
  COUPON: <Translate id="Supplier_Enumeration.Payment_Method.Coupon" />,
  LOCAL_SP: (
    <Translate id="booking_management_fundamental.popup_cancellation_charge_details.form.pay_at_local_reduced_promotion_discount" />
  ),
  CORPORATE_CHARGE: <Translate id="Supplier_Enumeration.Payment_Method.Corporate_Charge" />,
  PROVIDER: (
    <Translate id="booking_management_fundamental.popup_cancellation_charge_details.form.covered_by_provider" />
  ),
  RAKUTEN: (
    <Translate id="booking_management_fundamental.popup_cancellation_charge_details.form.covered_by_rakuten" />
  ),
  ONE_TIME: 'TLFX: One Time Payment',
};

export const TRANSACTION_STATUS_LABEL = {
  RESERVED: <Translate id="Supplier_Enumeration.Status.Booking.Booked" />,
  CANCELED: <Translate id="Supplier_Enumeration.Status.Booking.Cancelled" />,
  SHORTENED: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.kind_of_transition_modified" />
  ),
  MODIFIED: (
    <Translate id="booking_management_fundamental.popup_reservation_history_details.form.kind_of_transition_modified" />
  ),
};

export const HISTORY_RESERVATION_STATUS_LABEL = {
  RESERVED: <Translate id="Supplier_Enumeration.Status.Booking.Booked" />,
  CANCELED: <Translate id="Supplier_Enumeration.Status.Booking.Cancelled" />,
};

export const CANCELLATION_TYPE_LABEL = {
  NO_SHOW: <Translate id="Supplier_Enumeration.Status.Booking.No_Show" />,
  CANCELLED: <Translate id="Supplier_Enumeration.Status.Booking.Cancelled" />,
};

export const RAKUTEN_PROMOTION = 'RAKUTEN_PROMOTION';
export const PROVIDER_PROMOTION = 'PROVIDER_PROMOTION';
export const GOTO_PROMOTION = 'GOTO_PROMOTION';
export const TAX_FEE_INCLUDED = 'TAX_FEE_INCLUDED';
export const TAX_FEE_EXCLUDED = 'TAX_FEE_EXCLUDED';
export const PROVIDER_TYPE_RESCUE_HOTEL = 'RESCUE_HOTEL';
export const NOTE = 'NOTE';

export enum TAXES_AND_FEES_LABEL {
  CITY_TAX = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.City_Tax',
  TOTAL_TAX = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Total_Tax',
  VALUE_ADDED_TAX = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Value_Added_Tax',
  CONSUMPTION_TAX = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Consumption_Tax',
  HOTEL_TAX = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Hotel_Tax',
  HOT_SPRING_TAX = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Hot_Spring_Tax',
  CLEANING_FEE = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Cleaning_Fee',
  SERVICE_CHARGE_FEE = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Service_Charge_Fee',
  RESORT_FEE = 'Supplier_Enumeration.Supplier_Enumeration.Tax_Fee_Code.Resort_Fee',
}

export const RESERVATION_STATUS_LABEL: ENUMS<string> = {
  BOOKED: 'Supplier_Enumeration.Status.Booking.Booked',
  CANCELED: 'Supplier_Enumeration.Status.Booking.Cancelled',
  REQUESTING: 'booking_management_fundamental.booking_detail.form.requesting',
  MODIFICATION: 'Supplier_Enumeration.Transition_Type.Modified',
};

export const RESERVATION_STATUS_LABEL_BOOKING_LIST: ENUMS<string> = {
  BOOKED: 'booking_management_fundamental.list.list_column2.booked',
  CANCELED: 'booking_management_fundamental.list.list_column2.canceled',
  REQUESTING: 'booking_management_fundamental.booking_detail.form.requesting',
  MODIFICATION: 'Supplier_Enumeration.Transition_Type.Modified',
};

export const RESERVATION_CONFIRMATION_LABEL: ENUMS<string> = {
  UNCONFIRMED: 'booking_management_fundamental.list.list_column2.unconfirmed',
  CONFIRMED: 'Supplier_Enumeration.Status.Booking_Confirmation.Confirmed',
  MODIFIED: 'booking_management_fundamental.list.list_column2.modified',
  NOSHOW: 'booking_management_fundamental.list.list_column2.noshow',
};

export const RESERVATION_CONFIRMATION_ENUM: ENUMS<string> = {
  UNCONFIRMED: 'UNCONFIRMED',
  CONFIRMED: 'CONFIRMED',
  MODIFIED: 'MODIFIED',
  NOSHOW: 'NOSHOW',
  UNDEFINED: 'UNDEFINED',
};

export const COST_OWNER: ENUMS<ReactNode> = {
  PROVIDER: <Translate id="Supplier_Enumeration.Payment_Method.Provider" />,
  RAKUTEN: <Translate id="Supplier_Enumeration.Payment_Method.Rakuten" />,
};

export enum PROMOTION_TYPES {
  PROVIDER_SALES_PROMOTION = 'PROVIDER_SALES_PROMOTION',
  RAKUTEN_SALES_PROMOTION = 'RAKUTEN_SALES_PROMOTION',
  SPONSOR_PROMOTION = 'SPONSOR_PROMOTION',
}

export const PROMOTION_TYPES_LABELS: ENUMS<ReactNode> = {
  PROVIDER_SALES_PROMOTION: (
    <Translate id="booking_management_fundamental.popup_provider_promotion_details.form.provider_promotion" />
  ),
  RAKUTEN_SALES_PROMOTION: (
    <Translate id="booking_management_fundamental.popup_rakuten_promotion_details.form.rakuten_promotion" />
  ),
  SPONSOR_PROMOTION: (
    <Translate id="booking_management_fundamental.popup_rakuten_promotion_details.form.sponsored_promotion" />
  ),
};

export const PROMOTION_LABELS: ENUMS<ReactNode> = {
  DIRECT: <Translate id="booking_management_fundamental.booking_detail.form.direct_offer" />,
  MAIN: <Translate id="booking_management_fundamental.booking_detail.form.coupon_main" />,
  SUB: <Translate id="booking_management_fundamental.booking_detail.form.coupon_sub" />,
  GOTO: <Translate id="booking_management_fundamental.booking_detail.form.go_to" />,
};

export enum TRANSLATE_TYPES {
  NOTICE = 'NOTICE',
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
  SPECIAL_REQUEST = 'SPECIAL_REQUEST',
}

export const DESIGNATION: ENUMS<ReactNode> = {
  STANDARD: <Translate id="booking_management_fundamental.list.list_column6.standard" />,
  LUXURY: <Translate id="booking_management_fundamental.list.list_column6.luxury" />,
  JRDP: <Translate id="booking_management_fundamental.list.list_column6.jr" />,
  ANA: <Translate id="booking_management_fundamental.list.list_column6.ana" />,
  JAL: <Translate id="booking_management_fundamental.list.list_column6.jal" />,
  DOMESTIC: <Translate id="booking_management_fundamental.list.list_column6.domestic_hotel" />,
};

export const DESIGNATIONS_ENUM = {
  STANDARD: 'STANDARD',
  LUXURY: 'LUXURY',
  DOMESTIC: 'DOMESTIC',
  ANA: 'ANA',
  JAL: 'JAL',
  JRDP: 'JRDP',
};

export const RATE_PLAN_TYPE_ENUM = {
  STAY_PLAN: 'STAY_PLAN',
  STAY_WITHOUT_PLANS: 'STAY_WITHOUT_PLANS',
  DAY_USE: 'DAY_USE',
  SECRET_PLANS: 'SECRET_USE',
  MIDNIGHT: 'MIDNIGHT',
};

export const CONFIRMATION_STATUS: ENUMS<ReactNode> = {
  CONFIRMED: 'TLFX: Confirmed',
  UNCONFIRMED: 'TLFX: Unconfirmed',
};

export const RATE_PLAN_STATUS: ENUMS<ReactNode> = {
  ACTIVE: <Translate id="Supplier_Enumeration.Status.Sales.Active" />,
  INACTIVE: <Translate id="Supplier_Enumeration.Status.Sales.Inactive" />,
};

export const RATE_PLAN_STATUS_ENUM: ENUMS<string> = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const DP_COUPON_ORDER = [
  SERVICE_TYPE.RAKUTEN,
  SERVICE_TYPE.THIRD_PARTY,
  SERVICE_TYPE.DP_YADO,
];

export const USAGE_UNIT_TAX: ENUMS<ReactNode> = {
  CITY_TAX: <Translate id="booking_management_fundamental.booking_detail.form.accommodation_tax" />,
  HOT_SPRING_TAX: <Translate id="booking_management_fundamental.booking_detail.form.bathing_tax" />,
};

export const R_CONNECT_OPERATION_TYPES = {
  BOOKING_CONFIRM: 'BOOKING_CONFIRM',
  CANCEL_CONFIRM: 'CANCEL_CONFIRM',
  DELETE_BOOKING_QUEUE: 'DELETE_BOOKING_QUEUE',
  DELETE_CANCEL_QUEUE: 'DELETE_CANCEL_QUEUE',
  SHORTEN_CONFIRM: 'SHORTEN_CONFIRM',
  DELETE_SHORTEN_QUEUE: 'DELETE_SHORTEN_QUEUE',
};
export const R_CONNECT_OPERATIONTYPE_CONFIRM_STATUS = [
  R_CONNECT_OPERATION_TYPES.BOOKING_CONFIRM,
  R_CONNECT_OPERATION_TYPES.CANCEL_CONFIRM,
  R_CONNECT_OPERATION_TYPES.SHORTEN_CONFIRM,
];
export const R_CONNECT_OPERATIONTYPE_DELETE_QUEUE = [
  R_CONNECT_OPERATION_TYPES.DELETE_BOOKING_QUEUE,
  R_CONNECT_OPERATION_TYPES.DELETE_CANCEL_QUEUE,
  R_CONNECT_OPERATION_TYPES.DELETE_SHORTEN_QUEUE,
];
export const R_CONNECT_STATUS_CONFIRM = [
  'CONFIRMED_BOOKING',
  'CONFIRMED_CANCEL',
  'CONFIRMED_SHORTEN',
];

export const NOTIFICATION_QUEUE_TYPES = {
  BOOKED: 'BOOKED',
  SHORTEN: 'SHORTEN',
  CANCELED: 'CANCELED',
};

export enum DIALOG_TYPE {
  CONFIRM = 'CONFIRM',
  SHORTEN_DELETE = 'SHORTEN_DELETE',
  BOOKING_DELETE = 'BOOKING_DELETE',
  CANCEL_DELETE = 'CANCEL_DELETE',
}

export default RESERVATION_STATUS_COLOR;

export const BOOKING_LIST_LIMIT = 100;
