import Decimal from 'decimal.js-light';

import { MeasurementSystem } from '../constants';

export enum DecimalPlaces {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export type Sign = {
  domestic?: string;
  global?: string;
};

export enum PositivePatterns {
  SIGN_AMOUNT = '{sign}{amount}',
  SIGN_SPACE_AMOUNT = '{sign} {amount}',
  AMOUNT_SIGN = '{amount}{sign}',
  AMOUNT_SPACE_SIGN = '{amount} {sign}',
}

export enum NegativePatterns {
  MINUS_SIGN_AMOUNT = '-{sign}{amount}',
  SIGN_MINUS_AMOUNT = '{sign}-{amount}',
  SIGN_SPACE_MINUS_AMOUNT = '{sign} -{amount}',
  MINUS_AMOUNT_SIGN = '-{amount}{sign}',
  MINUS_AMOUNT_SPACE_SIGN = '-{amount} {sign}',
  PARANTHESES_SIGN_AMOUNT = '({sign}{amount})',
}

export type Pattern = {
  positive: PositivePatterns;
  negative: NegativePatterns;
};

export type DateFormat = {
  MD: string;
  YM: string;
  Y: string;
  L: string;
  LL: string;
  LLL: string;
  LT: string;
  LTwithPrefixMeridiem: string;
  LTS: string;
  LTSwithPrefixMeridiem: string;
  INPUT: string;
};

export type NumberFormatSeparators = { thousands: string; decimals: string };

export type Locale = {
  code: string;
  label: string;
};

export type PopularDestination = {
  location: string;
  id: string;
  order: number;
  locale: Locale[];
};

export type Meridiem = {
  ante: string;
  post: string;
};

export interface MarketConfig {
  _DEFAULT: {
    dateFormat: DateFormat;
    measurementSystem: MeasurementSystem;
    firstDayOfTheWeek: boolean;
    nameFormat: string;
    addressFormat: string;
    numberFormatSeparators: NumberFormatSeparators;
    isEmailSubscribedByDefault: boolean;
    popularDestinations: PopularDestination[];
  };
  // default market MUST have all the config, individual markets can have everything optional
  [key: string]: {
    dateFormat?: Partial<DateFormat>; // DateFormat but all formats are optional
  } & Partial<Omit<MarketConfig['_DEFAULT'], 'dateFormat'>>; // self-reference everything except dateFormat
}

export interface LanguageConfig {
  _DEFAULT: {
    dateLocaleCharacters: {
      default: {
        year: string;
        month: string;
        day: string;
      };
      short?: {
        year: string;
        month: string;
        day: string;
      };
    };
    prefixMeridiem: boolean;
    localeMeridiem?: Meridiem;
  };
  [key: string]: Partial<LanguageConfig['_DEFAULT']>;
}

export type DecimalValue = string | number | Decimal;
